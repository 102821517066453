var lastScroll = 0;

export var api_url = getApiUrl();
export var app_name = getAppName();
export var image_url = api_url.replace('api/', '');

function getApiUrl() {
    let host = window.location.hostname;
    if (host.indexOf('cacair') > -1)
        return 'https://cacair.com.br/api/';
    if (host.indexOf('easyair') > -1)
        return 'https://easyairservicos2.websiteseguro.com/api/';
    return 'http://easyair-api:8001/api/';
}
function getAppName() {
    let host = window.location.hostname;
    if (host.indexOf('cacair') > -1)
        return 'CAC AIR';
    if (host.indexOf('easyair') > -1)
        return 'Easy Air';
    return 'Airplane';
}
function scrollTop() {
    lastScroll = window.scrollY;
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 1)
}
function scrollLast() {
    setTimeout(() => {
        window.scrollTo(0, lastScroll);
    }, 1)
}
function getData(path) {
    return fetch(api_url + path, {
        method: "GET",
        headers: { Authorization: 'Bearer ' + getUser().access_token }
    }).then(data => {
        if (data.status === 401) {
            window.location.href = "/login";
        }
        return data.json();
    });
}
function postData(path, dataInput) {
    let user = getUser();
    let headers = user ? { Authorization: 'Bearer ' + user.access_token } : {};
    return fetch(api_url + path, {
        method: 'post',
        headers: headers,
        body: JSON.stringify(dataInput)
    }).then(data => data.json());
}
export function tableSort(key) {

    let direction = this.state.sortField === key ? 1 : -1;

    let data = this.state.items.sort((a, b) => {
        if (a[key] < b[key]) return 1 * direction;
        if (a[key] > b[key]) return -1 * direction;
        return 0;
    });
    this.setState({ items: data, sortField: (key === this.state.sortField ? '' : key) });
}
export function tableSortNumber(key, obj) {

    if (typeof (obj) !== 'string') {
        let data = this.state.sortField === key ?
            this.state.items.sort((a, b) => b[key] - a[key]) :
            this.state.items.sort((a, b) => a[key] - b[key]);
        this.setState({ items: data, sortField: (key === this.state.sortField ? '' : key) });
    }
    else {
        if (typeof (key) !== 'object') {
            let data = this.state.sortField === key ?
                this.state[obj].sort((a, b) => (b[key] || 0) - (a[key] || 0)) :
                this.state[obj].sort((a, b) => (a[key] || 0) - (b[key] || 0));
            this.setState({ [obj]: data, sortField: (key === this.state.sortField ? '' : key) });
        }
        else {
            let data = this.state.sortField === key[1] ?
                this.state[obj].sort((a, b) => (b[key[0]] ? b[key[0]][key[1]] : 0) - (a[key[0]] ? a[key[0]][key[1]] : 0)) :
                this.state[obj].sort((a, b) => (a[key[0]] ? a[key[0]][key[1]] : 0) - (b[key[0]] ? b[key[0]][key[1]] : 0));
            this.setState({ [obj]: data, sortField: (key[1] === this.state.sortField ? '' : key[1]) });
        }
    }

}
export function hideMore() {
    document.getElementById('menu-more').className = 'menu-more';
    document.body.className = '';
}
export function setUser(user) {
    if (user !== null)
        user.isCliente = Number(user.permission_id) === 3;
    localStorage.setItem("user", JSON.stringify(user))
}
export function isCliente() {
    return getUser() !== null && getUser().isCliente;
}
export function getUser() {
    var user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
}
export function formatNumber(x) {
    if (x == null || isNaN(x)) return "";
    var parts = x.toString().split(".");
    if (parts.length == 1)
        parts.push("00");
    if (parts[1].length > 2)
        parts[1] = parts[1].substring(0, 2);
    if (parts[1].length == 1)
        parts[1] += "0";

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
}
export function formatNumberNoDec(x) {
    if (x == null || isNaN(x)) return "";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts[0];
}
export function numberToDB(x) {
    if (!x) return 0;
    x = x.toString().replace(/\./g, '').replace(',', '.').replace("$", "");
    return isNaN(x) ? 0 : Number(x);
}
export function compare(value1, value2) {
    if (!value1 || !value2) return false;
    return value1.toString().toUpperCase().indexOf(value2) >= 0
}
export function expenseName(category, expense) {
    return category === "Geral" ? expense : category + ' - ' + expense;
}
export function checkDate(val) {
    if (val === null) return new Date();
    var date = new Date(val.split(' ')[0].replace(/-/g, '/'));

    if (isNaN(date.getFullYear()))
        return new Date();
    return date;
}
export function isValidDate(dateString, isDMY = null) {
    // First check for the pattern
    var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
    if (isDMY)
        regex_date = /^\d{2}\/\d{2}\/\d{4}$/;

    if (!regex_date.test(dateString)) {
        return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split("-");
    var day = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[0], 10);
    if (isDMY) {
        parts = dateString.split("/");
        day = parseInt(parts[0], 10);
        month = parseInt(parts[1], 10);
        year = parseInt(parts[2], 10);
    }

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
        return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
        monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}
export function formHide() {
    document.getElementById('new').className = 'form go';
    document.getElementById('list').className = 'div-table';
    document.getElementById('filter').className = 'filter';
    scrollLast();
}
export function formShow() {
    document.getElementById('new').className = 'form come';
    document.getElementById('list').className = 'hidden';
    document.getElementById('filter').className = 'hidden';
    scrollLast();
}
export function newDate() {
    let date = new Date();
    let day = date.getDate();
    day = day < 10 ? '0' + day : day;
    let month = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    return day + '/' + month + '/' + date.getFullYear();
}

export { scrollTop, scrollLast, getData, postData }
