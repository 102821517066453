import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DateCombo from './DateCombo'
import CurrencyFormat from 'react-currency-format';
import * as common from './Common';

class FlightLeg extends Component {
    constructor(props) {
        super(props);
        common.getData('flight-leg/breadcrumb/' + props.match.params.flight_id).then((data) => {
            this.setState({ parent: data.parent });
        });
    }
    componentDidMount() {

        //Legs
        common.getData('combo/flight-leg/' + this.props.match.params.flight_id).then((data) => {
            this.setState({ legs: data });
        });
        if (parseInt(this.props.match.params.id) === 0) {
            common.getData('parameter/get-by-name/GAL_PRICE').then(data => {
                this.setState({ gal_price: data.value.replace('.', ',') });
                this.newData();
            });
        }
        else
            this.editData();


    }
    bindList() {
        this.props.show();
        var that = this;
        common.getData('flight-fueling/by-flight-id/' + this.props.match.params.flight_id).then((data) => { that.props.hide(); this.setState({ items: data }) });
    }
    save() {
        let data = this.state.data;
        if (!data.station || data.station === "") return alert('Preencha o campo Station!');
        if (!data.lts || data.lts === "") return alert('Preencha o campo LTS!');
        this.props.show();
        var that = this;
        data.user_id = common.getUser().id;
        common.postData('flight-fueling', data).then(function (data) {
            that.props.hide();
            if (data.message) return alert(data.message);
            that.back();
        });

    }
    state = {
        items: null,
        data: this.getNewData(),
        gal_price: '20',
        legs: []

    }
    barList() {
        this.props.changeTitle({ left: <div className="btn-back" onClick={() => { this.props.history.push('/flight-leg/' + this.props.match.params.flight_id) }}><i className="fas fa-arrow-alt-circle-left"></i> {this.state.parent}</div>, center: 'Abastecimento', right: <button type="button" onClick={() => this.newData()} className="btn-right">Novo</button> });
    }
    barForm = () => {
        this.props.changeTitle({ left: <div className="btn-back" onClick={() => { this.back() }}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>, center: 'Abastecimento' });
    }
    back() {
        this.props.history.push('/flight-leg/' + this.props.match.params.flight_id);
    }
    handleChange = e => {
        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({ data })

    }
    newData() {
        this.setState({ data: this.getNewData() });
        document.getElementById('new').className = 'form come';
        this.barForm();

    }
    editData = () => {
        this.props.show();
        common.getData('flight-fueling/' + this.props.match.params.id).then((data) => {
            this.props.hide();
            let date_fueling = new Date(data.date_fueling_view);
            data.year = date_fueling.getFullYear();
            data.month = date_fueling.getMonth() + 1;
            data.day = date_fueling.getDate();
            data.gal = data.gal ? data.gal.toString().replace('.', ',') : data.gal;
            data.gal_price = data.gal_price ? data.gal_price.toString().replace('.', ',') : data.gal_price;
            this.setState({ data: data })
            document.getElementById('new').className = 'form come';
            this.barForm();
        });
    }
    getNewData() {
        return {
            id: 0,
            flight_id: this.props.match.params.flight_id,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
            gal_price: this.state ? this.state.gal_price : '',
            provider: 'BR',
            broker: 'COLT',
        }
    }
    render() {

        return (
            <React.Fragment>
                {(!this.state.items) ? <div></div> :
                    this.state.items.length == 0 ? <div className="bg-cream rounded p-2">- Ainda não há abastecimento cadastrado</div> :
                        <div className="rounded">
                            {this.state.items.map((x, i) => <div className={'row no-gutters position-relative mb-1 rounded ' + (i % 2 === 0 ? 'bg-cream' : 'bg-cream-dark')} onClick={this.editData.bind(this, x)} key={x.id} >
                                <div className="col-1 bg-blue text-center" ><div className="v-align" >{i + 1}</div></div>
                                <div className="col p-1 pt-0">
                                    <div className="row">
                                        <div className="col">
                                            <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.station}</div>
                                        </div>
                                        <div className="col">{x.date_fueling_view}</div>
                                        <div className="w-100 mb-1" ></div>
                                        <div className="col" ><b>LTS:</b> {x.lts || 0}  </div>
                                        <div className="col" ><b>Broker:</b> {x.broker || ''}</div>
                                        <div className="w-100" ></div>
                                        <div className="col" ><b>Provider:</b> {x.provider || ''}</div>
                                        <div className="col" ><b>Invoice:</b> {x.invoice || ''}</div>
                                        <div className="w-100" ></div>
                                        <div className="col" ><b>US$/Gal.:</b> {x.gal_price || ''}</div>
                                    </div>
                                </div>
                            </div>)}

                        </div>
                }
                <div id="new" className="form">
                    <div className="row no-gutters" >
                        <div className="col-12">
                            <div className="label">Data</div>
                            <DateCombo year={this.state.data.year} month={this.state.data.month} day={this.state.data.day} onChange={this.handleChange} />
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="label">Trecho</div>
                            <select className="form-control" name="leg_id" value={this.state.data.leg_id} onChange={this.handleChange} >
                                <option value="" >Trechos</option>
                                {this.state.legs.map((x, i) => <option key={i} value={x.id} >{x.name}</option>)}
                            </select>
                        </div>
                        <div className="w-100" ></div>
                        {this.getControl('Station', 'station', "", { textTransform: 'uppercase' })}
                        {this.getControlNumber('LTS.', 'lts', false)}
                        {this.getControlNumber('GAL..', 'gal', false)}
                        <div className="w-100" ></div>
                        {this.getControl('Broker', 'broker', "", { textTransform: 'uppercase' })}
                        {this.getControl('Provider', 'provider', "", { textTransform: 'uppercase' })}
                        <div className="w-100" ></div>
                        {this.getControl('Invoice', 'invoice', "", { textTransform: 'uppercase' })}
                        {this.getControlNumber('US$/GAL.', 'gal_price', false)}
                    </div>
                    <div className="text-right pt-2">
                        <button className="btn btn-main" onClick={() => this.save()} >Salvar</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    getControl = (label, field, mask, css) => {
        return (
            <div className="col mr-1">
                <div className="label">{label}</div>
                <input type="text" autoComplete="on" style={css} className="form-control" name={field} value={this.state.data[field] || ""} onChange={this.handleChange} />
            </div>
        )
    }
    getControlNumber = (label, field, disabled) => {
        return (
            <div className="col mr-1">
                <div className="label">{label}</div>
                <CurrencyFormat type="tel" value={this.state.data[field] || ""} disabled={disabled} className="form-control" onKeyUp={this.setFuelRemaining} name={field} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} onBlur={this.handleBlur} />
            </div>
        )
    }
    handleBlur = (e) => {
        if (e.target.value === "") return;
        if (e.target.name === 'lts') {
            let num = common.numberToDB(e.target.value);
            console.log(num);
            let data = this.state.data;
            data.gal = common.formatNumber(num / 3.78);
            this.setState({ data });
        }
        else if (e.target.name === 'gal') {
            let num = common.numberToDB(e.target.value);
            console.log(num);
            let data = this.state.data;
            data.lts = common.formatNumber(num * 3.78);
            this.setState({ data });
        }
    }
}
export default withRouter(FlightLeg)