import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format';
import cameraIcon from '../../images/camera-icon-3.png'
import * as common from './../Common';
import DateCombo from './../DateCombo'
import pdfIconMini from '../../images/pdf_icon_mini.jpg'
import pdfIconMedium from '../../images/pdf_icon_medium.png'

class OtherExpense extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        this.barList();
        this.bindList();
        common.getData('combo/other-expense-type').then((data) => { this.setState({ expenses: data }) });
        common.getData('combo/currency').then((data) => { this.setState({ currencies: data }) });

        if (this.props.location.search !== "")
            this.newData();
    }
    bindList() {
        this.props.show();
        var that = this;
        common.getData('other-expense').then((data) => { that.props.hide(); this.setState({ itemsAll: data, items: data }) });
    }
    save() {

        let data = this.state.data;
        if (!data.amount || data.amount === "") return alert('Preencha o valor!');
        if (!data.currency_id || data.currency_id === "0") return alert('Selecione a moeda!');

        this.props.show();
        var that = this;
        data.user_id = common.getUser().id;
        common.postData('other-expense', data).then(function (data) {
            that.props.hide();
            that.bindList();
            that.back();
        });
    }
    state = {
        items: null,
        expenses: [],
        data: this.getNewData(),
        currencies: []
    }
    getNewData() {

        return {
            id: 0,
            expense_id: 1,
            images: [],
            amount: "",
            description: "",
            currentImage: "",
            type_id: 1,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
        }
    }
    barList() {
        this.props.changeTitle({
            left: null
            , center: "Despesas Avulsas",
            right: <button type="button" onClick={() => this.newData()} className="btn-right">Novo</button>
        });
    }
    barForm = () => {
        this.props.changeTitle({
            left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>
            , center: 'Despesa'
        });
    }
    back() {
        this.barList();

        document.getElementById('list').className = '';
        document.getElementById('new').className = 'form go';
        document.getElementById('filter').className = 'filter';

    }
    handleChange = e => {
        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({ data })

    }
    newData = () => {
        this.setState({ data: this.getNewData() });

        document.getElementById('list').className = 'hidden';
        document.getElementById('new').className = 'form come';
        document.getElementById('filter').className = 'hidden';

        this.barForm();

    }
    editData = (item) => {
        this.props.show();
        common.getData('other-expense/' + item.id).then((data) => {
            this.props.hide();
            data.amount = data.amount.toString().replace(".", ",");
            if (data.amount_dollar)
                data.amount_dollar = data.amount_dollar.toString().replace(".", ",");
            else
                data.amount_dollar = '';

            data.currentImage = data.images.length > 0 ? data.images[0] : '';
            let date_expense = new Date(data.date_expense);

            data.year = date_expense.getFullYear();
            data.month = date_expense.getMonth() + 1;
            data.day = date_expense.getDate();
            this.setState({ data: data })

            document.getElementById('list').className = 'hidden';
            document.getElementById('new').className = 'form come';
            document.getElementById('filter').className = 'hidden';

            this.barForm();
        });
    }
    filter(e) {
        let items = [];
        if (e.target.value == '')
            items = this.state.itemsAll;
        else {
            let value = e.target.value.toUpperCase();
            items = this.state.itemsAll.filter(x =>
                x.expense_name.toUpperCase().indexOf(value) >= 0 || x.date_expense.indexOf(value) >= 0);
        }

        this.setState({ items });
    }
    deleteExpense = (x, e) => {
        e.stopPropagation();
        if (!window.confirm('Deseja excluir a despesa ' + x.expense_name + '?'))
            return;

        this.props.show();
        var that = this;
        common.postData('other-expense/delete-expense', x).then(function (data) {
            if (data === 1) {
                that.props.hide();
                that.bindList();
            }
            else {
                alert('Erro ao excluir a despesa!');
            }

        });
    }
    deleteImage = () => {

        if (!window.confirm('Confirma a excluxão deste comprovante?'))
            return;
        this.props.show();
        var that = this;

        common.postData('other-expense/delete-upload',
            { filename: this.state.data.currentImage }).then((dataReturn) => {
                that.props.hide();
                let data = this.state.data;
                if (data.images && data.images.length > 0) {
                    let index = data.images.map(x => x.image_name).indexOf(this.state.data.currentImage);
                    data.images.splice(index, 1);
                    data.currentImage = "";
                    if (data.images.length > 0) {
                        data.currentImage = data.images[0];
                    }
                    this.setState({ data });
                }
            });
    }

    render() {

        return (
            <React.Fragment>
                <div className="filter" id="filter" >
                    <input type="text" className="form-control form-control-sm col-md-8 offset-md-2" placeholder="Buscar..." onChange={this.filter.bind(this)} />
                </div>
                <div className="page-margin-top"></div>
                <div id="list">
                    {(!this.state.items) ? <div></div> :
                        this.state.items.length == 0 ? <div className="bg-cream rounded p-2">- Nenhuma despesa foi encontrada</div> :
                            <React.Fragment>
                                <div className="hidden-xs">
                                    <table className="table table-light table-hover table-bordered table-striped table-sm" >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th onClick={common.tableSort.bind(this, 'date_expense')} >Data</th>
                                                <th onClick={common.tableSort.bind(this, 'expense_name')} >Despesa</th>
                                                <th onClick={common.tableSort.bind(this, 'description')} >Descrição</th>
                                                <th onClick={common.tableSort.bind(this, 'user_name')} >Autor</th>
                                                <th onClick={common.tableSortNumber.bind(this, 'currency_id')} >Moeda</th>
                                                <th style={{ whiteSpace: 'nowrap' }} onClick={common.tableSortNumber.bind(this, 'amount')} >Valor</th>
                                                <th style={{ whiteSpace: 'nowrap' }} onClick={common.tableSortNumber.bind(this, 'amount_dollar')} >US$</th>
                                                <th className="text-center" onClick={common.tableSortNumber.bind(this, 'image_count')} >Comp.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map(x => <tr key={x.id} onClick={this.editData.bind(this, x)} >
                                                <td onClick={this.deleteExpense.bind(this, x)} className="text-center" ><i className="fas fa-trash-alt font-sm" ></i></td>
                                                <td>{x.date_expense_view}</td>
                                                <td>{x.expense_name}</td>
                                                <td>{x.description}</td>
                                                <td>{x.user_name}</td>
                                                <td>{x.currency_id}</td>
                                                <td className={x.amount_dollar ? 'text-success' : 'text-danger'}><b>{common.formatNumber(x.amount)}</b></td>
                                                <td>{common.formatNumber(x.amount_dollar)}</td>
                                                <td className="text-center" >{x.image_count || 0}</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="show-xs">
                                    {this.state.items.map((x, i) => <div className={'row no-gutters m-0 pointer ' + (i % 2 === 0 ? 'bg-cream' : 'bg-cream-dark')} onClick={this.editData.bind(this, x)} key={x.id} >
                                        <div className="col-1 p-0 bg-blue text-center" ><div className="v-align">{i + 1}</div></div>
                                        <div className="col p-1">
                                            <div><span className="font-sm">{x.date_expense_view}</span> <span className="hidden-xs">- <b>{x.expense_name}</b></span> - {x.user_name}</div>
                                            <div className="show-xs"><b>{x.expense_name}</b></div>
                                            {x.description != null ? <div className="font-sm"><i>{x.description}</i></div> : null}
                                        </div>
                                        <div className="col-auto text-center pt-1 pr-1">
                                            <div hidden={x.currency_id === 'USD'} className="badge badge-danger bg-red block">{x.currency_id} {common.formatNumber(x.amount)}
                                            </div>
                                            <div hidden={!x.amount_dollar} className="badge badge-success bg-success">USD {common.formatNumber(x.amount_dollar)}
                                            </div>
                                            <div className="text-right pr-2"><i className="fas fa-image font-lg ml-1 "></i><small> {x.image_count || 0}</small></div>
                                        </div>

                                    </div>)}

                                </div>
                            </React.Fragment>

                    }
                </div>
                <div id="new" className="form">
                    <div>
                        <div className="label">Data</div>
                        <DateCombo year={this.state.data.year} month={this.state.data.month} day={this.state.data.day} onChange={this.handleChange} />
                    </div>
                    <div>
                        <div className="label">Despesa</div>
                        <select className="form-control" name="type_id" value={this.state.data.type_id} onChange={this.handleChange} >
                            {this.state.expenses.map((x, i) => <option key={i} value={x.id} >{x.name}</option>)}
                        </select>
                    </div>
                    <div className="row no-gutters" >
                        <div className="col-6 pr-1" >
                            <div className="label">Método</div>
                            <select className="form-control" name="method_id" value={this.state.data.method_id || 'CC'} onChange={this.handleChange} >
                                <option value="CC">Cartão</option>
                                <option value="MO">Dinheiro</option>
                                <option value="WI">Wire</option>
                            </select>
                        </div>
                        <div className="col-6 pl-1" >
                            <div className="label">Moeda</div>
                            <select className="form-control" name="currency_id" value={this.state.data.currency_id || '0'} onChange={this.handleChange} >
                                <option value="0">Moeda</option>
                                {this.state.currencies.map((x, i) => <option key={i} value={x.code} >{x.name} - {x.code}</option>)}


                            </select>
                        </div>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-6 pr-1" >
                            <div className="label">Valor Original</div>
                            <CurrencyFormat value={this.state.data.amount} placeholder="Valor" className="form-control" name="amount" decimalScale={2} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} />
                        </div>
                        <div className="col-6 pr-1" >
                            <div className="label">Valor U$ <small>(* se necessário)</small></div>
                            <CurrencyFormat value={this.state.data.amount_dollar} placeholder="Valor em Dolar" className="form-control" name="amount_dollar" decimalScale={2} prefix={'$'} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} />
                        </div>
                    </div>
                    <div>
                        <div className="label">Descrição</div>
                        <input type="text" placeholder="Descriçao" className="form-control" name="description" value={this.state.data.description || ""} onChange={this.handleChange} />
                    </div>
                    <div className="p-1 pt-3">
                        <input type="file" id="myFile" name="myFile" onChange={this.upload} hidden accept=".jpg, .jpeg, .gif, .png, .bmp, .pdf"  ></input>
                        <div className="text-center mb-2">
                            <img className="rounded block-inline img-fluid pointer mr-1 mb-1  expense-mini p-1" style={{ width: 50 }} onClick={() => { document.getElementById('myFile').click() }} src={cameraIcon} alt="" />
                            {this.state.data.images.map((x, i) =>
                                <React.Fragment key={'image' + i} >
                                    {x.toLowerCase().indexOf('.pdf') < 0 ?
                                        <img key={'image' + i} onClick={this.setImage.bind(this, x)} className="rounded block-inline img-fluid mr-1 mb-1 expense-mini" src={common.image_url + 'upload/other_expenses/mini/' + x} alt="" />
                                        : <img onClick={this.setImage.bind(this, x)} className="rounded block-inline img-fluid mr-1 mb-1 expense-mini" src={pdfIconMini} alt="" />
                                    }
                                </React.Fragment>


                            )}
                        </div>
                        <div className="text-center mb-1">
                            {this.state.data.images.length > 0 ?
                                <div>
                                    {
                                        this.state.data.currentImage.toLowerCase().indexOf('.pdf') < 0 ?
                                        <img className="rounded block-inline img-fluid expense-medium" src={common.image_url + 'upload/other_expenses/medium/' + this.state.data.currentImage} alt="" />
                                            : <div>
                                                <b>{this.state.data.currentImage}</b><br />
                                                <a href={common.image_url + 'upload/other_expenses/doc/' + this.state.data.currentImage} target="_blank" >
                                                    <img className="rounded block-inline img-fluid expense-medium" src={pdfIconMedium} alt="" />
                                                </a>
                                            </div>
                                    }
                                    <div className="mt-2 text-center"><button className="btn btn-sm btn-danger p-1" onClick={this.deleteImage} >Excluir</button></div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="text-right pt-2">
                        <button className="btn btn-main" onClick={() => this.save()} >Salvar</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    setImage = (img) => {
        let data = this.state.data;
        data.currentImage = img;
        this.setState({ data });
    }
    upload = () => {

        var myFiles = document.getElementById('myFile');
        var file = myFiles.files[0];
        var formData = new FormData();
        formData.append('file', file);
        formData.append('id', this.state.data.id);
        let expense = this.state.expenses.filter(x => x.id == this.state.data.type_id)[0];
        formData.append('expense', expense.name);

        this.props.show();
        var that = this;

        fetch(common.api_url + 'shared/upload-other-expense', {
            method: 'post', body: formData
        }).then(dataReturn => dataReturn.text()).then(function (dataReturn) {
            that.props.hide();
            if (dataReturn == "0") {
                alert('Não foi possível obter a foto.')
            }
            let data = that.state.data;
            data.images.push(dataReturn);
            data.currentImage = dataReturn;
            that.setState({ data });

        });
    }

}
export default withRouter(OtherExpense)