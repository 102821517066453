import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format';
import pdfIconMedium from '../images/pdf_icon_mini.jpg'

import * as common from './Common';

class FlightImage extends Component {
    constructor(props) {
        super(props);

        common.getData('flight-leg/breadcrumb/' + props.match.params.id).then((data) => {
            this.setState({ parent: data.parent }); this.barList();
        });

    }
    componentDidMount() {
        this.bindList();
    }
    bindList() {
        this.props.show();
        var that = this;
        common.getData('flight-images/' + this.props.match.params.id).then((data) => { that.props.hide(); this.setState({ items: data }) });
    }
    state = {
        items: null
    }

    barList() {
        this.props.changeTitle({
            left: <div className="btn-back" onClick={() => { this.props.history.push('/') }}><i className="fas fa-arrow-alt-circle-left"></i> {this.state.parent}</div>
            , center: "Comprovantes"
        });
    }
    back() {
        this.barList();
        document.getElementById('new').className = 'form go';
    }
    render() {

        return (
            <React.Fragment>
                {(!this.state.items) ? <div></div> :
                    this.state.items.length === 0 ? <div className="bg-cream rounded p-2 text-center">Ainda não há nenhum comprovante para este vôo</div> :
                        <React.Fragment>
                            {this.state.items.map((x, i) =>
                                <div key={i} className="mb-2 text-left">
                                    <div className="rounded">
                                        <div className="bg-blue p-1  flight-image">
                                            <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.f_from}</div>
                                            <i className="fas fa-arrow-circle-right ml-1 mr-1"></i>
                                            <div className="badge badge-success mr-2" style={{ width: 60, fontSize: 12 }} >{x.f_to}</div>
                                            <div className="float-right">{x.expense_date}</div>
                                            <div className="mt-1">
                                                <b> {x.type_name} - {x.expense_name}</b> - {x.user_name}
                                                <div className="float-right">{x.currency_id} ${common.formatNumber(x.amount)}</div>

                                            </div>
                                            {x.description ? <div><i>{x.description}</i></div> : null}
                                        </div>
                                        <div className="bg-cream p-1 text-center" hidden={x.image_name !== ""}>Comprovante ainda não informado</div>
                                        <div className="text-center bg-cream" >
                                            {
                                            x.image_name.toLowerCase().indexOf('.pdf') < 0 ?
                                            <img className="block-inline img-fluid" src={common.image_url + 'upload/expenses/' + this.state.parent + '/big/' + x.image_name} alt="" />
                                                : <div className="pt-1 pb-1">
                                                    <b>{x.image_name}</b><br />
                                                    <a href={common.image_url + 'upload/expenses/' + this.state.parent + '/doc/' + x.image_name} target="_blank" >
                                                        <img className="block-inline img-fluid" src={pdfIconMedium} alt="" />
                                                    </a>
                                                </div>
                                        }
                                        </div>
                                    </div>
                                </div>

                            )}
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }
}
export default withRouter(FlightImage)