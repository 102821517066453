import React, { Component } from 'react';
import * as common from '../Common';
import CurrencyFormat from 'react-currency-format';
import MyModal from '../MyModal';


class ExpenseVariableList extends Component {
  constructor(props) {
    super(props);

    this.barList();
    this.bindList();

  }
  state = {
    items: [],
    itemsAll: [],
    sortField: '',
    filter: '',
    permissions: [],
    data: {},
    currencies: [],
    convert_currency_id: "0",
    statusHistoric : []


  }
  barList() {
    this.props.changeTitle({ left: null, center: 'Despesas Variáveis' });
  }
  barForm = () => {
    this.props.changeTitle({ left: <div className="btn-back" onClick={this.back.bind(this)}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div> });
  }
  bindList() {
    this.props.show();
    var that = this;
    common.getData('leg-expense').then((data) => {
      that.props.hide();
      data.forEach(x => { x.amount_dollar = x.amount_dollar ? x.amount_dollar.toString().replace(".", ",") : null });
      this.setState({ items: data, itemsAll: data })
    });
    common.getData('combo/currency-pending').then((currencies) => {
      this.setState({ currencies });
    });
  }
  newData() {
    common.scrollTop();
    this.setState({ data: this.getNewData() });
    document.getElementById('new').className = 'form come';
    document.getElementById('list').className = 'hidden';
    document.getElementById('filter').className = 'hidden';

    this.barForm();
  }
  back() {
    this.barList();
    document.getElementById('new').className = 'form go';
    document.getElementById('list').className = 'div-table';
    document.getElementById('filter').className = 'filter';
    common.scrollLast();
  }
  editData(item) {
    this.props.show();
    common.getData('crew/' + item.id).then((data) => {
      this.props.hide();
      common.scrollTop();
      data.active = data.active === '1';
      this.setState({ data: data })
      document.getElementById('new').className = 'form come';
      document.getElementById('list').className = 'hidden';
      document.getElementById('filter').className = 'hidden';
      this.barForm();
    });
  }
  save() {
    this.props.show();
    var that = this;
    common.postData('crew', this.state.data).then(function (data) {
      that.props.hide();
      that.bindList();
      that.back();
    });

  }
  getNewData() {

    return {
      id: 0,
      active: 1,
      permission_id: 1
    }
  }
  handleChangeList = (x, e) => {
    let items = this.state.items;
    let item = items.find(y => y.id === x.id);

    if (e.target.type === 'checkbox')
      item[e.target.name] = e.target.checked ? 1 : 0;
    else
      item[e.target.name] = e.target.value;

    this.setState({ items })

  }
  handleChange = (e) => {


    if (e.target.name === "convert_currency_id") {
      let items = this.state.itemsAll;
      if (e.target.value !== "0")
        items = items.filter(x => x.currency_id === e.target.value);
      this.setState({ [e.target.name]: e.target.value, items })
    }
    else {
      this.setState({ [e.target.name]: e.target.value })
    }

    setTimeout(() => {

      let items = this.state.items;
      items.forEach(x => {
        if (x.currency_id === this.state.convert_currency_id && x.method_id === "CC" && !x.saved) {
          if (!this.state.convert_amount_dollar) {
            x.amount_dollar = null;
            x.changed = false;
          }
          else {
            x.amount_dollar = common.formatNumber(x.amount / common.numberToDB(this.state.convert_amount_dollar));
            x.changed = true;
          }
        }
        else if (x.changed) {
          x.amount_dollar = null;
          x.changed = false;
        }
        this.setState({ items });
      });
    }, 1);

  }
  saveChange = (x, e) => {
    x.amount_dollar = !x.amount_dollar || x.amount_dollar === "" ? "" : x.amount_dollar.toString().trim();
    common.postData('leg-expense/save-dollar', { rows: [x], user_id: common.getUser().id }).then((data) => { })
  }
  filter(e) {

    let value = e.target.value.toUpperCase();
    let items = [];
    if (value === '')
      items = this.state.itemsAll;
    else {
      items = this.state.itemsAll.filter(x => common.compare(x.unique_id, value) ||
        common.compare(x.user_name, value) || common.compare(x.expense_name, value) ||
        common.compare(x.amount, value) || common.compare(x.date_expense_view, value)
        || common.compare(x.f_from, value) || common.compare(x.f_to, value));
    }
    this.setState({ items });

  }

  convertCurrency = () => {
    let itemsToSave = this.state.items.filter(x => x.changed);
    if (itemsToSave.length === 0)
      return alert('Nenhum valor foi preenchido para conversão!');

    if (!window.confirm('Confirma a conversão dos valores marcados em azul abaixo?'))
      return;

    this.props.show();
    itemsToSave.forEach(x => {
      x.changed = false;
      x.saved = common.numberToDB(x.amount_dollar) !== 0
    });
    common.postData('leg-expense/save-dollar', { rows: itemsToSave, user_id: common.getUser().id }).then((data) => {
      if (data == "1") {
        this.setState({ items: itemsToSave, convert_amount_dollar: null });
        setTimeout(() => {
          this.props.hide();
          alert("Dados salvos com sucesso!");
        }, 1000);
      }
      else {
        this.props.hide();
        alert("Houve um erro ao salvar os dados!");
      }
    });
  }
  changeStatus = (x, e) => {
    e.stopPropagation();

    if (!x.amount_dollar || x.amount_dollar === "" || x.amount_dollar === 0)
      return alert('Valor em dolar não foi informado!');

    if (x.status !== 1) {
      if (!window.confirm('Confirma a validação dessa despesa U$' + x.amount_dollar + '?'))
        return;
    }
    else {
      if (!window.confirm('Confirma a reabertura dessa despesa U$' + x.amount_dollar + '?'))
        return;
    }

    x.user_id = common.getUser().id;
    x.status = x.status !== 1 ? 1 : 0;
    common.postData('leg-expense/change-status', x).then((data) => {
      x.status_count = data;
      this.setState({ items: this.state.items });

    });
  }
  openStatusWindow = (x, e) => {
    e.stopPropagation();
    common.getData('leg-expense/list-status/' + x.id).then((data) => {

      this.setState({ showModal: true, statusHistoric : data });

    });
  }

  render() {

    return (
      <React.Fragment>
        <MyModal handleShow={this.state.showModal} handleClose={() => { this.setState({ showModal: false }) }} title={'Histórico Status'} >
          <div>
            <table className="table table-light table-bordered table-striped table-sm" >
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Autor</th>
                  <th>Data</th>
                </tr>
              </thead>
              <tbody>
                {this.state.statusHistoric.map((x, i) => <tr key={i} >
                <td className={x.status === "1"  ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'}>{x.status === "1" ? "Validado" : "Reaberto"}</td>
                <td>{x.name}</td>
                <td>{x.updated_at}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </MyModal>
        <div className="filter" id="filter" >
          <div className="row">
            <div className="col-sm-4 mb-1">
              <input type="text" className="form-control form-control-sm" placeholder="Buscar..." onChange={this.filter.bind(this)} />
            </div>
            <div className="col-sm-2 mb-1">
              <select className="form-control form-control-sm mr-1" name="convert_currency_id" value={this.state.convert_currency_id || ""} onChange={this.handleChange} >
                <option value="0" >Moeda</option>
                {this.state.currencies.map((x, i) => <option key={i} value={x.id}>{x.id} ({x.count})</option>)}
              </select>
            </div>
            <div className="col-sm-2 mb-1">
              <CurrencyFormat value={this.state.convert_amount_dollar || ""} onChange={this.handleChange}
                className="form-control form-control-sm mr-1" name="convert_amount_dollar"
                decimalScale={2} prefix={'$'} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," />
            </div>
            <div className="col-sm-2 mb-1">
              <button className="btn btn-primary btn-sm" onClick={this.convertCurrency} >Converter</button>
            </div>
          </div>
        </div>
        <div className="div-table expense-variable-list " id="container" >
          <div id="list" className="table-responsive">
            <table className="table table-light table-bordered table-striped table-sm" >
              <thead>
                <tr>
                  <th onClick={common.tableSort.bind(this, 'unique_id')} >Vôo</th>
                  <th onClick={common.tableSort.bind(this, 'f_from')} >Trecho</th>
                  <th onClick={common.tableSort.bind(this, 'expense_name')} >Despesa</th>
                  <th onClick={common.tableSort.bind(this, 'date_expense')} >Data</th>
                  <th onClick={common.tableSort.bind(this, 'user_name')} >Autor</th>
                  <th onClick={common.tableSort.bind(this, 'method_id')} >Método</th>
                  <th onClick={common.tableSortNumber.bind(this, 'amount_dollar')} >Moeda</th>
                  <th onClick={common.tableSortNumber.bind(this, 'amount')} >Valor</th>
                  <th onClick={common.tableSort.bind(this, 'currency_id')} >US$</th>
                  <th>Status</th>
                  <th className="text-center">Comp.</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map(x => <tr key={x.id} >
                  <td>{x.unique_id}</td>
                  <td>{x.f_from} - {x.f_to}</td>
                  <td>{x.expense_name}</td>
                  <td>{x.date_expense_view}</td>
                  <td>{x.user_name}</td>
                  <td>{x.method_name}</td>
                  <td>{x.currency_id}</td>
                  <td>{common.formatNumber(x.amount)}</td>
                  <td className={x.changed ? 'changed' : ''}>
                    <CurrencyFormat value={x.amount_dollar || ""} onChange={this.handleChangeList.bind(this, x)}
                      onBlur={this.saveChange.bind(this, x)} disabled={x.status === 1}
                      className={'list-input ' + (x.status === 1 ? 'text-dark font-weight-bold' : 'text-secondary')} name="amount_dollar"
                      decimalScale={2} prefix={'$'} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," />
                  </td>
                  <td >
                  <span hidden={x.status !== 1} onClick={this.changeStatus.bind(this, x)} className='pointer' title="Reabrir" >{x.updated_at_view}</span>
                    <i hidden={x.status === 1} className={'fas fa-check-square pointer'} onClick={this.changeStatus.bind(this, x)} title="Validar"></i>
                    <i className="fas fa-exclamation-triangle text-warning ml-1 pointer" hidden={!x.status_count || x.status_count < 2}
                      title="Alterado mais de uma vez" onClick={this.openStatusWindow.bind(this, x)}></i>
                       <i className="fas fa-check text-success-dark ml-1 pointer" hidden={!x.status_count || x.status_count > 1}
                      title="Ver Detalhe" onClick={this.openStatusWindow.bind(this, x)}></i>
                  </td>
                  <td className="text-center">
                    {x.images ?
                      x.images.split(',').map((y, i) =>
                        <i key={i} onClick={this.showImage.bind(this, y, x)} className="fas fa-image big-icon mr-1 pointer" ></i>
                      )
                      : null}
                    <i className="fas fa-plus-circle pointer" onClick={this.addImage.bind(this, x)} title="Adicionar comprovante" ></i>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>
          {this.state.image_name &&
            <div className="image-modal" id="image-modal">
              <img src={common.image_url + 'upload/expenses/' + this.state.unique_id + '/big/' + this.state.image_name} alt="Comprovante" onLoad={() => { this.props.hide() }} />
              <div className="bg-cream p-1 rounded">
                <button className="btn btn-sm btn-danger p-1" onClick={this.deleteImage} >Excluir</button>
                <button className="btn btn-sm btn-success p-1 float-right" onClick={this.closeImage} >Fechar</button>
              </div>
            </div>
          }
        </div>
        <input type="file" id="myFile" name="myFile" onChange={this.upload} hidden accept=".jpg, .jpeg, .gif, .png, .bmp"  ></input>
      </React.Fragment>
    );
  }
  showImage = (image_name, itemSelected, e) => {
    this.props.show();
    this.setState({ image_name, unique_id: itemSelected.unique_id, itemSelected })

  }
  closeImage = () => {
    this.setState({ image_name: null, unique_id: null })
  }
  addImage = (item, e) => {
    e.stopPropagation();
    document.getElementById('myFile').click()
    this.setState({ itemSelected: item });

  }
  upload = () => {

    var myFiles = document.getElementById('myFile');
    if (myFiles.files.length === 0 || !myFiles.files[0] || myFiles.files[0] === "")
      return;

    var file = myFiles.files[0];
    let itemSelected = this.state.itemSelected;

    var formData = new FormData();
    formData.append('file', file);
    formData.append('id', itemSelected.id);
    formData.append('flight', itemSelected.unique_id);
    formData.append('leg', itemSelected.f_from + '-' + itemSelected.f_to);
    formData.append('expense', itemSelected.expense_name);
    formData.append('user_id', common.getUser().id);

    this.props.show();
    var that = this;

    fetch(common.api_url + 'shared/upload', {
      method: 'post', body: formData
    }).then(dataReturn => dataReturn.text()).then(function (dataReturn) {
      that.props.hide();
      if (dataReturn == "0") {
        alert('Não foi possível obter a foto.')
      }
      let items = that.state.items;
      if (!itemSelected.images || itemSelected.images === "")
        itemSelected.images = dataReturn;
      else
        itemSelected.images += "," + dataReturn;

      that.setState({ items });

    });
  }
  deleteImage = (e) => {

    e.stopPropagation();
    if (!window.confirm('Confirma a excluxão deste comprovante?'))
      return;
    this.props.show();
    var that = this;

    let itemSelected = this.state.itemSelected;

    common.postData('leg-expense/delete-image',
      { id: 0, flight: itemSelected.unique_id, filename: this.state.image_name }).then((dataReturn) => {
        that.props.hide();
        this.setState({ image_name: null, unique_id: null })
        this.bindList();

      });
  }
}


export default ExpenseVariableList;
