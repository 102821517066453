import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as common from './Common';

class Menu extends Component {

  componentWillReceiveProps() {

  }
  componentDidMount() {
    common.getData('apps').then((data) => { this.setState({ apps: data }); })
  }
  state = {
    apps: [],
    user: common.getUser() ? common.getUser().name : "",
    permission_id: common.getUser().permission_id,
    menuItems: [
      { name: 'Aeronave', path: '/admin/airplane', icon: 'fas fa-plane', permissions: [] },
      { name: 'Log Book', path: '/logbook-leg', icon: 'fas fa-book', permissions: [] },
      { name: 'Usuários', path: '/admin/user', icon: 'fas fa-user-circle', permissions: [] },
      { name: 'Passageiros', path: '/admin/passenger', icon: 'fas fa-users', permissions: [] },
      { name: 'Tripulacao', path: '/admin/crew', icon: 'fas fa-plane-departure', permissions: [3] },
      { name: 'Despesas Variáveis', path: '/admin/expense-variable-list', icon: 'fas fa-credit-card', permissions: [3] },
      { name: 'Despesas Fixas', path: '/admin/month-expense', icon: 'fas fa-coins', permissions: [3] },
      { name: 'Despesas Avulsas', path: '/admin/other-expense', icon: 'fas fa-coins', permissions: [3] },
      { name: 'Tabela de Despesas Variáveis', path: '/admin/expense', icon: 'fas fa-list', permissions: [] },
      { name: 'Tabela de Despesas Fixas', path: '/admin/expense-fixed', icon: 'fas fa-list', permissions: [] },
      { name: 'Fornecedores', path: '/admin/vendor', icon: 'fas fa-briefcase', permissions: [] },
      { name: 'Parâmetros', path: '/admin/parameter', icon: 'fas fa-cogs', permissions: [] },
    ]
  }
  redirect = (path) => {

    common.hideMore();
    if (document.location.pathname !== '/site' + path)
      this.props.history.push(path);
    else {
      this.props.history.push('/default');
      setTimeout(() => {
        this.props.history.push(path);
      }, 1);
    }
  }
  redirectLastLeg() {
    common.hideMore();
    this.props.show();
    common.getData('flight-leg-last').then((data) => {
      this.props.hide();
      if (data === "0")
        return alert('Ainda não há nenhum trecho cadastrado');
      this.redirect('/leg-expense/' + data.flight_id + '/' + data.id + '?id=new');
    });
  }

  showMore(e) {
    e.stopPropagation();
    document.getElementById('menu-more').className = 'menu-more menu-come';
    setTimeout(() => {
      document.body.className = 'no-overflow';
    }, 600);

  }

  render() {

    return (
      <React.Fragment>
        <div className="menu-more" id="menu-more" onClick={common.hideMore} >
          <div className="text-center title" id="title">
            <i className="fas fa-plane"> {common.app_name} </i>
          </div>
          <div className="col-md-8 offset-md-2 menu-list">
            <div>
              <div>Welcome aboard <b>{common.getUser() ? common.getUser().name : ""}!</b></div>
            </div>
            {this.state.menuItems.map((x, i) => <React.Fragment key={i} >
              {this.state.permission_id == 1 || x.permissions.find(x => x == this.state.permission_id) ?
                <div key={i} >
                  <div onClick={this.redirect.bind(this, x.path)}  ><i className={x.icon} ></i> {x.name}</div>
                </div> : null}
            </React.Fragment>
            )}
            <div>
              <div onClick={() => { common.setUser(null); this.redirect('/login') }}  ><i className="fas fa-sign-out-alt"></i> Sair</div>
            </div>
          </div>
        </div>
        <div className="menu row no-gutters" >
          <div className="col-md-8 offset-md-2 row no-gutters">
            <div className="col" >
              <div onClick={this.redirect.bind(this, '/')}  ><i className="fas fa-plane"></i><div>Vôos</div></div>
            </div>
            <div className="col" hidden={common.isCliente()} >
              <div onClick={this.redirectLastLeg.bind(this)} ><i className="fas fa-money-check-alt"></i><div>Despesa</div></div>
            </div>
            <div className="col" >
              <div onClick={this.redirect.bind(this, '/dashboard')} ><i className="fas fa-chart-bar"></i><div>Dashboard</div></div>
            </div>
            <div className="col" >
              <div onClick={this.showMore.bind(this)}  ><i className="fas fa-bars"></i><div>Mais</div></div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default withRouter(Menu);
