import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format';
import cameraIcon from '../images/camera-icon-3.png'
import pdfIconMini from '../images/pdf_icon_mini.jpg'
import pdfIconMedium from '../images/pdf_icon_medium.png'
import * as common from './Common';
import DateCombo from './DateCombo'

class LegExpense extends Component {
    constructor(props) {
        super(props);

        common.getData('leg-expense/breadcrumb/' + props.match.params.id).then((data) => {
            this.setState({ parent1: data.unique_id, parent2: data.f_from + ' - ' + data.f_to });
            if (this.props.location.search == "")
                this.barList()
        });
    }
    componentDidMount() {
        this.bindList();
        common.getData('combo/expense').then((data) => { this.setState({ expenses: data }) });
        common.getData('combo/currency').then((data) => { this.setState({ currencies: data }) });

        if (this.props.location.search !== "")
            this.newData();
    }
    bindList() {
        this.props.show();
        var that = this;
        common.getData('leg-expense/by-leg-id/' + this.props.match.params.id).then((items) => {
            that.props.hide();

            this.setState({ items })
        });
    }
    save() {
        let data = this.state.data;

        if (!common.isValidDate(data.year + "-" + data.month + "-" + data.day)) return alert('Data Inválida!');
        if (!data.amount || data.amount === "") return alert('Preencha o valor!');
        if (data.expense_id == 2 && (!data.qtd || data.qtd === '')) return alert('Preencha a quantidade!');
        if (!data.currency_id || data.currency_id === "0") return alert('Selecione a moeda!');
        this.props.show();
        var that = this;
        data.user_id = common.getUser().id;
        common.postData('leg-expense', data).then(function (data) {
            that.props.hide();
            that.bindList();
            that.back();
        });
    }
    state = {
        items: null,
        expenses: [],
        data: this.getNewData(),
        currencies: []
    }
    getNewData() {

        return {
            id: 0,
            expense_id: 1,
            leg_id: this.props.match.params.id,
            images: [],
            amount: "",
            description: "",
            currentImage: "",
            currency_id: 0,
            method_id: 'CC',
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
        }
    }
    barList() {
        this.props.changeTitle({
            left: <div className="btn-back" onClick={() => { this.props.history.push('/flight-leg/' + this.props.match.params.flight_id) }}><i className="fas fa-arrow-alt-circle-left"></i> Trechos</div>
            , center: "Despesas",
            right: <button type="button" onClick={() => this.newData()} className="btn-right" hidden={common.isCliente()}  >Novo</button>
        });
    }
    barForm = () => {
        this.props.changeTitle({
            left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>
            , center: 'Despesa'
        });
    }
    back() {
        this.barList();
        document.getElementById('new').className = 'form go';
    }
    handleChange = e => {
        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({ data })
    }
    newData = () => {
        this.setState({ data: this.getNewData() });
        document.getElementById('new').className = 'form come';
        this.barForm();

    }
    editData = (item) => {

        if (item.status === "1")
            return alert('Despesa já validada!');

        this.props.show();
        common.getData('leg-expense/' + item.id).then((data) => {
            this.props.hide();
            var date_expense = common.checkDate(data.date_expense);
            data.year = date_expense.getFullYear();
            data.month = date_expense.getMonth() + 1;
            data.day = date_expense.getDate();

            data.amount = data.amount.toString().replace(".", ",");

            data.currentImage = "";
            data.currentImageId = 0;
            if (data.images.length > 0) {
                data.currentImage = data.images[0].image_name;
                data.currentImageId = data.images[0].id;
            }


            this.setState({ data: data })
            document.getElementById('new').className = 'form come';


            this.barForm();
        });
    }
    getUnitValue() {

        if (!this.state.data.amount) return '';
        let amount = Number(this.state.data.amount.replace('$', '').replace(/\./g, '').replace(',', '.'));
        if (isNaN(amount) || isNaN(this.state.data.qtd))
            return '';
        return common.formatNumber(amount / this.state.data.qtd);

    }
    deleteExpense = (x, e) => {
        e.stopPropagation();


        if (!window.confirm('Deseja excluir esta despesa?'))
            return;

        x.flight = this.state.parent1;
        this.props.show();
        var that = this;
        common.postData('leg-expense/delete-expense', x).then(function (data) {
            that.props.hide();
            that.bindList();
        });
    }

    render() {

        return (
            <React.Fragment>
                <div className="filter" id="filter" >
                    <div className="row" >
                        <div className="col-md-8 offset-md-2 text-center">
                            <div className="btn-back block-inline badge badge-primary font-md mr-3" onClick={() => { this.props.history.push('/') }}>
                                Vôo: {this.state.parent1}
                            </div>
                            <div className="btn-back block-inline badge badge-success  font-md" onClick={() => { this.props.history.push('/flight-leg/' + this.props.match.params.flight_id) }}>
                                Trecho: {this.state.parent2}
                            </div>
                        </div>
                    </div>
                </div>
                {(!this.state.items) ? <div></div> :
                    this.state.items.length == 0 ? <div className="bg-cream rounded p-2 extra-margin-top">- Ainda não há nenhuma despesa cadastrada</div> :
                        <div className="rounded extra-margin-top">
                            {this.state.items.map((x, i) => <div className={'row no-gutters m-0 pointer ' + (i % 2 === 0 ? 'bg-cream' : 'bg-cream-dark')} onClick={common.isCliente() ? null : this.editData.bind(this, x)} key={x.id} >
                                <div className="col-1 p-0 bg-blue text-center" onClick={common.isCliente() ? null : this.deleteExpense.bind(this, x)} >
                                    <div className="v-align">{i + 1}
                                        <div hidden={common.isCliente() || x.status === "1"}  ><i className="fas fa-trash-alt font-sm" ></i></div>
                                    </div>
                                </div>
                                <div className="col p-1">
                                    <div className="font-xs">{x.date_expense}</div>
                                    <div><b>{common.expenseName(x.category_name, x.expense_name)}</b></div>
                                    {x.description != null ? <div className="font-sm"><i>{x.description}</i></div> : null}
                                </div>
                                <div className="col-auto text-right pr-1">
                                    <div hidden={x.currency_id === 'USD'} className="badge badge-danger bg-red block">{x.currency_id} {common.formatNumber(x.amount)}
                                    </div>
                                    <div hidden={!x.amount_dollar} className="badge badge-success bg-success">USD {common.formatNumber(x.amount_dollar)}
                                    </div>
                                    <div><i className="fas fa-image font-lg ml-1 mt-2"></i><small> {x.image_count || 0}</small></div>
                                </div>

                            </div>)}

                        </div>
                }
                <div id="new" className="form ">
                    <div className="extra-margin-top">
                        <div>
                            <div className="label">Data</div>
                            <DateCombo year={this.state.data.year} month={this.state.data.month} day={this.state.data.day} onChange={this.handleChange} />
                        </div>
                        <div>
                            <div className="label">Despesa</div>
                            <select className="form-control" name="expense_id" value={this.state.data.expense_id} onChange={this.handleChange} >
                                {this.state.expenses.length === 0 ? <option>Carregando...</option> :
                                    this.state.expenses.map((x, i) => <option key={i} value={x.id} >{(x.category_name === 'Geral' ? '' : x.category_name + ' - ') + x.name}</option>)}
                            </select>
                        </div>
                        <div className="row no-gutters" >
                            <div className="col-6 pr-1" >
                                <div className="label">Valor</div>
                                <CurrencyFormat value={this.state.data.amount} placeholder="Valor" className="form-control" name="amount" decimalScale={2} prefix={'$'} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} />
                            </div>
                            <div className="col-6 pl-1" >
                                <div className="label">Invoice</div>
                                <input type="text" placeholder="Invoice" className="form-control" name="invoice" value={this.state.data.invoice || ""} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="row no-gutters" >
                            <div className="col-6 pr-1" >
                                <div className="label">Método</div>
                                <select className="form-control" name="method_id" value={this.state.data.method_id || 'CC'} onChange={this.handleChange} >
                                    <option value="CC">Cartão</option>
                                    <option value="MO">Dinheiro</option>
                                    <option value="WI">Wire</option>
                                </select>
                            </div>
                            <div className="col-6 pl-1" >
                                <div className="label">Moeda</div>
                                <select className="form-control" name="currency_id" value={this.state.data.currency_id || '0'} onChange={this.handleChange} >
                                    <option value="0">Moeda</option>
                                    {this.state.currencies.map((x, i) => <option key={i} value={x.code} >{x.name} - {x.code}</option>)}


                                </select>
                            </div>
                        </div>
                        <div className="row no-gutters" hidden={this.state.data.expense_id != 2} > {/* Somente para Abastecimento */}
                            <div className="col-6 pr-1" >
                                <div className="label">Quantidade Galões</div>
                                <CurrencyFormat value={this.state.data.qtd || ''} placeholder="Quantidade" className="form-control" name="qtd" decimalScale={0} fixedDecimalScale={true} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} />
                            </div>
                            <div className="col-6 pl-1" >
                                <div className="label">Valor Unitário</div>
                                <input type="text" value={this.getUnitValue()} disabled={true} placeholder="Valor Unitário" className="form-control" />
                            </div>
                        </div>
                        <div>
                            <div className="label">Descrição</div>
                            <input type="text" placeholder="Descriçao" className="form-control" name="description" value={this.state.data.description} onChange={this.handleChange} />
                        </div>
                        <div className="p-1 pt-3">
                            <input type="file" id="myFile" name="myFile" onChange={this.upload} hidden accept=".jpg, .jpeg, .gif, .png, .bmp, .pdf"  ></input>
                            <div className="text-center mb-2">
                                <img className="rounded block-inline img-fluid pointer mr-1 mb-1  expense-mini p-1" style={{ width: 50 }} onClick={() => { document.getElementById('myFile').click() }} src={cameraIcon} alt="Imagem" />
                                {this.state.data.images.map((x, i) =>
                                    <React.Fragment key={'image' + i} >
                                        {x.image_name.toLowerCase().indexOf('.pdf') < 0 ?
                                            <img onClick={this.setImage.bind(this, x)} className="rounded block-inline img-fluid mr-1 mb-1 expense-mini" src={common.image_url + 'upload/expenses/' + this.state.parent1 + '/mini/' + x.image_name} alt="" />
                                            : <img onClick={this.setImage.bind(this, x)} className="rounded block-inline img-fluid mr-1 mb-1 expense-mini" src={pdfIconMini} alt="" />
                                        }
                                    </React.Fragment>


                                )}
                            </div>
                            <div className="text-center mb-1">
                                {this.state.data.images.length > 0 ?
                                    <div>
                                        {
                                            this.state.data.currentImage.toLowerCase().indexOf('.pdf') < 0 ?
                                                <img className="rounded block-inline img-fluid expense-medium" src={common.image_url + 'upload/expenses/' + this.state.parent1 + '/medium/' + this.state.data.currentImage} alt="" />
                                                : <div>
                                                    <b>{this.state.data.currentImage}</b><br />
                                                    <a href={common.image_url + 'upload/expenses/' + this.state.parent1 + '/doc/' + this.state.data.currentImage} target="_blank" >
                                                        <img className="rounded block-inline img-fluid expense-medium" src={pdfIconMedium} alt="" />
                                                    </a>
                                                </div>
                                        }
                                        <div className="mt-2 text-center"><button className="btn btn-sm btn-danger p-1" onClick={this.deleteImage} >Excluir</button></div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="text-right pt-2">
                        <button className="btn btn-main" onClick={() => this.save()} >Salvar</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    setImage = (img) => {
        let data = this.state.data;
        data.currentImage = img.image_name;
        data.currentImageId = img.id;
        this.setState({ data });
    }
    upload = () => {

        var myFiles = document.getElementById('myFile');
        if (myFiles.files.length === 0 || !myFiles.files[0] || myFiles.files[0] === "")
            return;

        var file = myFiles.files[0];
        var formData = new FormData();
        formData.append('file', file);
        formData.append('id', this.state.data.id);
        formData.append('flight', this.state.parent1);
        formData.append('leg', this.state.parent2);
        let expense = this.state.expenses.filter(x => x.id == this.state.data.expense_id)[0];
        formData.append('expense', expense.name);
        formData.append('user_id', common.getUser().id);

        this.props.show();
        var that = this;

        fetch(common.api_url + 'shared/upload', {
            method: 'post', body: formData
        }).then(dataReturn => dataReturn.text()).then(function (dataReturn) {
            that.props.hide();
            if (dataReturn == "0") {
                alert('Não foi possível obter a foto.')
            }
            let data = that.state.data;
            data.images.push({ image_name: dataReturn, id: 0 });
            data.currentImage = dataReturn;
            data.currentImageId = 0;
            that.setState({ data });

        });
    }
    deleteImage = () => {

        if (!window.confirm('Confirma a excluxão deste comprovante?'))
            return;
        this.props.show();
        var that = this;

        common.postData('leg-expense/delete-image',
            { id: this.state.data.currentImageId, flight: this.state.parent1, filename: this.state.data.currentImage }).then((dataReturn) => {
                that.props.hide();
                let data = this.state.data;
                if (data.images && data.images.length > 0) {
                    let index = data.images.map(x => x.image_name).indexOf(this.state.data.currentImage);
                    data.images.splice(index, 1);
                    data.currentImage = ""
                    data.currentImageId = 0;
                    if (data.images.length > 0) {
                        data.currentImage = data.images[0].image_name;
                        data.currentImageId = data.images[0].id;
                    }
                    this.setState({ data });
                }
            });
    }

}
export default withRouter(LegExpense)