import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './App.css';
import './Logbook.css';

import Menu from './components/Menu';
import Flight from './components/Flight';
import FlightLeg from './components/FlightLeg';
import FlightFueling from './components/FlightFueling';
import LegExpense from './components/LegExpense';
import FlightImage from './components/FlightImage';
import Login from './components/Login';
import loadingImage from './images/loading-spinner.svg';
import User from './components/admin/User';
import Passenger from './components/admin/Passenger';
import Crew from './components/admin/Crew';
import Expense from './components/admin/Expense';
import ExpenseFixed from './components/admin/ExpenseFixed';
import MonthExpense from './components/admin/MonthExpense';
import OtherExpense from './components/admin/OtherExpense';
import ExpenseVariableList from './components/admin/ExpenseVariableList';
import Vendor from './components/admin/Vendor';
import Airplane from './components/admin/Airplane';
import Parameter from './components/admin/Parameter';
import Dashboard from './components/Dashboard';
import LogbookLeg from './components/LogbookLeg';
import LogbookBook from './components/admin/LogbookBook';
import LogbookPage from './components/LogbookPage';


import * as common from './components/Common';

import { BrowserRouter, Route } from 'react-router-dom'

class App extends Component {
  constructor(props) {
    super(props);

    if (window.location.pathname !== '/login' && common.getUser() === null)
      return window.location.href = "/login";
  }
  state = {
    title: { left: '', center: 'Welcome Aboard!', right: '' },
    loading: '',
  }
  changeTitleHandler = title => {
    if (!title.left)
      title.left = <div><i className="fas fa-plane"></i><span className="hidden-xs"> {common.app_name}</span></div>;
    this.setState({ title: title });
  }
  loadingShow = () => {
    this.setState({ loading: 'loading-show', isloading: true });
  }
  loadingHide = () => {
    this.setState({ loading: '', isloading: false });
  }
  componentWillMount() {
    if (common.getUser() === null)
      return;

    // common.getData('user/' + common.getUser().id).then(data => {
    //   common.setUser(data);
  
    // });

  }
  dateChanged = (e) => {
    this.setState({ year: e.target.value });
  }
  render() {

    return (
      <React.Fragment>
        <div className="navigation-bar row no-gutters" >
          <div className={'row no-gutters ' + (window.location.pathname !== '/dashboard'
            && window.location.pathname !== '/admin/expense-variable-list'
            && window.location.pathname !== '/admin/month-expense' ? 'col-md-8 offset-md-2 ' : 'col-12')}>
            <div className="col text-left align-self-center" >{this.state.title.left}</div>
            <div className="col-auto text-center align-self-center" >{this.state.title.center}</div>
            <div className="col text-right align-self-center" >{this.state.title.right}</div>
          </div>
        </div>

        <BrowserRouter basename="/">
          <div className={'page p-1  ' + (window.location.pathname !== '/dashboard'
            && window.location.pathname !== '/admin/expense-variable-list'
            && window.location.pathname !== '/admin/month-expense' ? 'col-md-8 offset-md-2 ' : 'col-12')}>

            {window.location.pathname === '/login' || <Menu show={this.loadingShow} hide={this.loadingHide} />}
            <Route path="/login" render={() => <Login changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/" exact render={() => <Flight changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/flight-leg-edit/:id/:leg_id" render={() => <FlightLeg changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} isLoading={this.state.isloading} />} />
            <Route path="/flight-leg/:id" render={() => <FlightLeg changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} isLoading={this.state.isloading} />} />
            <Route path="/flight-fueling/:flight_id/:id" render={() => <FlightFueling changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/leg-expense/:flight_id/:id" render={() => <LegExpense changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/flight-image/:id" render={() => <FlightImage changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/dashboard" render={() => <Dashboard changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/logbook-leg" render={() => <LogbookLeg changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/logbook-book" render={() => <LogbookBook changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/logbook-page" render={() => <LogbookPage changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/default" />
            <Route path="/admin/user" render={() => <User changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/passenger" render={() => <Passenger changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/crew" render={() => <Crew changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/expense" render={() => <Expense changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/expense-fixed" render={() => <ExpenseFixed changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/vendor" render={() => <Vendor changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/month-expense" render={() => <MonthExpense changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/other-expense" render={() => <OtherExpense changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/airplane" render={() => <Airplane changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/parameter" render={() => <Parameter changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />
            <Route path="/admin/expense-variable-list" render={() => <ExpenseVariableList changeTitle={this.changeTitleHandler} show={this.loadingShow} hide={this.loadingHide} />} />


          </div>
        </BrowserRouter>
        <div className={'loading ' + this.state.loading} ><img src={loadingImage} alt="" /></div>

      </React.Fragment>
    );
  }
}

export default App;
