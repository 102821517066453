import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as common from './Common';
import Chart from './Chart';
// import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// var CanvasJSReact = require('../chart/canvasjs.react').default;
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.barList();

  }
  barList() {

    this.props.changeTitle({ left: null, center: 'Dashboard', right: <div className="" onClick={this.showFilter.bind(this)}><i className="fas fa-filter show-xs"></i></div> });
  }
  barForm = () => {
    this.props.changeTitle({ left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>, center: 'Vôo' });
  }
  bindList() {

    var that = this;


    common.getData(`report/dashboard/${this.state.year}/${this.state.month}/0/${this.state.flight_type}/${this.state.flight_id}/${this.state.leg_id}`).then((dt) => {
      that.props.hide();

      let data = {
        days_flying_count: dt.days_flying_count || 0,
        world_km: dt.world_km ? common.formatNumberNoDec(parseInt(dt.world_km)) : 0,
        world_time: dt.world_time || 0,
        passenger_count: dt.passenger_count || 0,
        passenger_cost: dt.passenger_cost || 0,
        hour_cost: dt.hour_cost || 0,
        countries: dt.country,
        cities: dt.city,
        continents: dt.continent,
        cost_fixed: dt.cost_fixed,
        cost_variable: dt.cost_variable,
        cost_total: dt.cost_total,
        cost_variable_by_flight: dt.cost_variable_by_flight,
        cost_fueling: dt.cost_fueling,
        leg_count: dt.leg_count
      };
      this.setState({ data });
    });
  }
  componentDidMount() {

    //Combos and Data
    this.props.show();
    common.getData('combo/year').then((years) => {
      this.setState({ years });
      this.getFlights()
      this.getFlightTypes()
      this.bindList();
    });
  }
  getFlights = () => {
    return common.getData(`combo/flight/${this.state.flight_type}/${this.state.year}/${this.state.month}`).then((flights) => {
      this.setState({ flights, flight_legs: [], flight_id: "0", leg_id: "0" });
    });
  }
  getFlightLegs = () => {
    return common.getData(`combo/flight-leg/${this.state.flight_id}`).then((flight_legs) => {
      this.setState({ flight_legs });
    });
  }
  getFlightTypes = () => {
    common.getData('combo/flight-types').then((data) => { this.setState({ flight_types: data.map( x=> x.id) }) });
  }
  state = {
    itemsAll: [],
    items: null,
    sortField: '',
    years: [{ name: 'Ano' }],
    passengers: [],
    crew: [],
    data_count_by_month_old: [],
    data_count_by_month: [],
    months: [{ id: "01", name: "Jan" }, { id: "02", name: "Fev" }, { id: "03", name: "Mar" }, { id: "04", name: "Abr" }, { id: "05", name: "Mai" }, { id: "06", name: "Jun" },
    { id: "07", name: "Jul" }, { id: "08", name: "Ago" }, { id: "09", name: "Set" }, { id: "10", name: "Out" }, { id: "11", name: "Nov" }, { id: "12", name: "Dez" }
    ],
    airplanes: [{ id: 1, name: 'Avião Principal' }],
    flight_types: [],
    flights: [],
    flight_legs: [],
    data: {
      days_flying_count: 0,
      world_km: 0,
      world_time: 0,
      passenger_count: 0,
      passenger_cost: 0,
      hour_cost: 0,
      countries: [],
      cities: [],
      continents: [],
      cost_fixed: [],
      cost_variable: [],
      cost_total: [],
      cost_variable_by_flight: [],
      cost_fueling: [],

      leg_count: []

    },
    flight_type: '0',
    year: new Date().getFullYear(),
    month: '0',
    flight_id: "0",
    leg_id: "0"
  }
  handleChange = e => {
    let data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({ data })

  }
  showFilter() {
    var css = document.getElementById('filter').className;
    css = css.indexOf('hidden-xs') > 0 ? 'filter dashboard-filter' : 'filter dashboard-filter hidden-xs';
    document.getElementById('filter').className = css;
  }
  hideFilter() {
    document.getElementById('filter').className = 'filter dashboard-filter hidden-xs';
  }
  filter(e) {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === 'year' || e.target.name === 'month' || e.target.name === 'flight_type') {
      setTimeout(() => {
        this.getFlights().then(() => { this.props.show(); this.bindList() });
        ;
      }, 1);
    }
    if (e.target.name === 'flight_id') {
      setTimeout(() => {
        this.getFlightLegs().then(() => { this.props.show(); this.bindList() });
        ;
      }, 1);
    } else {
      setTimeout(() => { this.props.show(); this.bindList() }, 1);
    }



  }
  getPeriodLabel = () => {

    if (this.state.leg_id !== "0") return <span>em {this.state.flight_legs.find(x => x.id == this.state.leg_id).name}</span>
    if (this.state.flight_id !== "0") return <span>em {this.state.flights.find(x => x.id == this.state.flight_id).name}</span>
    if (this.state.year !== "0") return <span>em {this.state.year}</span>
    if (this.state.year !== "0" && this.state.month !== "0") return <span>em {this.state.year}/{this.state.month}</span>
    if (this.state.year !== "0") return <span>em {this.state.year}</span>
    if (this.state.month !== "0") return <span>em {this.state.month}</span>

    return null;
  }
  closeFilter = () => {
    console.log(1);
    var css = document.getElementById('filter').className;
    if (css.indexOf('hidden-xs') < 0)
      this.hideFilter();

  }
  render() {
    const options = {
      color: 'red',
      animationEnabled: true,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      theme: "light",
      title: {
        text: "Vôos por mês em " + this.state.year
      },
      axisX: {
        labelFontColor: 'rgb(220, 220, 220)'
      },
      axisY: {
        labelFontColor: 'rgb(255, 255, 255)'
      },
      data: [{
        indexLabelFontColor: "darkgray",
        type: "column",
        dataPoints: this.state.data_count_by_month
      }]
    }
    const data = this.state.data_count_by_month_rechart;
    const dataChart = [{ x: 'a', y: 22 }, { x: 'b', y: 24 }, { x: 'c', y: 36 }, { x: 'd', y: 17 }, { x: 'e', y: 87 }, { x: 'f', y: 15 }];
    const dataChart2 = [{ x: 1, y: 1110 }, { x: 4, y: 1970 }, { x: 2, y: 800 }, { x: 8, y: 60 }, { x: 5, y: 208 }, { x: 3, y: 1114 }];

    return (
      <React.Fragment>
        <div className="filter dashboard-filter hidden-xs" id="filter" >
          <div className="row" >
            <div className="col-sm-4 col-md-2" >
              <select name="year" className="form-control" value={this.state.year} onChange={this.filter.bind(this)} >
                <option value="0">Todos Anos</option>
                {this.state.years.map((x, i) => <option key={i} value={x.name}>{x.name}</option>)}
              </select>
            </div>
            <div className="col-sm-4 col-md-2" >
              <select name="month" className="form-control" value={this.state.month} onChange={this.filter.bind(this)} >
                <option value="0">Todos Meses</option>
                {this.state.months.map((x, i) => <option key={i} value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className="col-sm-4 col-md-2" >
              <select name="airplane" className="form-control" value={this.state.airplane} onChange={this.filter.bind(this)} >
                {this.state.airplanes.map((x, i) => <option key={i} value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className="col-sm-4 col-md-2" >
              <select name="flight_type" className="form-control" value={this.state.flight_type} onChange={this.filter.bind(this)} >
                <option value="0">Todos Tipos</option>
                {this.state.flight_types.map((x, i) => <option key={i} value={x}>{x}</option>)}
              </select>
            </div>
            <div className="col-sm-4 col-md-2" >
              <select name="flight_id" className="form-control" value={this.state.flight_id} onChange={this.filter.bind(this)} >
                <option value="0">Todos Vôos</option>
                {this.state.flights.map((x, i) => <option key={i} value={x.id}>{x.name}</option>)}
              </select>
            </div>
            <div className="col-sm-4 col-md-2" >
              <select name="leg_id" className="form-control" value={this.state.leg_id} onChange={this.filter.bind(this)} >
                <option value="0">Todos Trechos</option>
                {this.state.flight_legs.map((x, i) => <option key={i} value={x.id}>{x.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="page-margin-top page-margin-top-sm page-margin-top-xs"></div>
        <div className="row m-0 dashboard  bg-dashboard" onClick={this.closeFilter.bind(this)} >
          <div className="col-12 mb-2">
            <div className="row m-0 dashboard-panel mt-2" >
              <div className="col-6 col-sm-4 col-md-3" >
                <div className="bg-purple">
                  <div className="bg-panel-icon"><i className="far fa-calendar-alt"></i> </div>
                  <b className="title"><i className="far fa-calendar-alt"></i> Trechos</b>
                  <div className="text-center mt-3">
                    <h4> {this.state.data.leg_count}</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-6 col-sm-4 col-md-3" >
                <div className="bg-warning-dark">
                  <b className="title"><i className="fas fa-globe"></i> Lugares Visitados</b>
                  <div className="pt-2 row country-list no-gutters" >
                    {this.state.data.visited_countries.map((x, i) => <React.Fragment key={i} >
                      <div className={'col-8 ' + (i % 2 === 0 ? '' : 'alternate')} ><b>{x.name}: </b></div> <div className="col-4 text-center" ><b>{x.count}</b></div>
                    </React.Fragment>)}
                  </div>
                </div>
              </div> */}
              <div className="col-6 col-sm-4 col-md-3" >
                <div className="bg-info">
                  <div className="bg-panel-icon"><i className="fas fa-globe-americas"></i> </div>
                  <b className="title"> <i className="fas fa-globe-americas"></i> Volta ao Mundo</b>
                  <div className="pt-3 font-lg hidden-xs">
                    <div className="mb-1"><b>KM:</b> {this.state.data.world_km} <b>Voltas:</b> {common.formatNumber(common.numberToDB(this.state.data.world_km) / 40000)}</div>
                    <div><b>Horas:</b> {this.state.data.world_time}</div>
                  </div>
                  <div className="pt-2 font-md show-xs">
                    <div><b>KM:</b> {this.state.data.world_km}</div>
                    <div><b>Horas:</b> {this.state.data.world_time}</div>
                    <div><b>Voltas:</b> {common.formatNumber(common.numberToDB(this.state.data.world_km) / 40000)}</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3" >
                <div className="bg-primary">
                  <div className="bg-panel-icon"><i className="fas fa-users"></i> </div>
                  <b className="title"><i className="fas fa-users"></i> Total Passageiros</b>
                  <div className="text-center mt-3">
                    <h4> {this.state.data.passenger_count}</h4>
                  </div>
                </div>
              </div>
              <div className="col-6 col-sm-4 col-md-3" ><div className="bg-success">
                <div className="bg-panel-icon"><i className="fas fa-coins"></i> </div>
                <b className="title"><i className="fas fa-coins"></i> Custo Hora Voada</b>
                <div className="text-center pt-3">
                  <h4>$ {common.formatNumber(this.state.data.hour_cost)}</h4>
                </div>
              </div>
              </div>
            </div >
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Continentes Visitados {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.continents} id="1" color="rgb(255, 186, 84)" lighten={true} direction="H" />
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Países Visitados {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.countries} id="2" color="rgb(138, 188, 73)" lighten={true} direction="H" />
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Cidades Visitadas {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.cities} id="3" color="rgb(138, 188, 73)" lighten={true} direction="H" />
          </div>
          <div className="col-12 mb-3">
            <h6>Custo por {this.state.flight_id !== '0' ? 'Trecho' : 'Vôo'}  (USD) {this.getPeriodLabel()}</h6>
            <div className="table-responsive" >
              <Chart width={this.state.data.cost_variable_by_flight.length > 12 ? this.state.data.cost_variable_by_flight.length * 50 : null} data={this.state.data.cost_variable_by_flight} id="cost_variable_by_flight" barWidthMin="50" direction="V" currency="$" color="rgb(255, 186, 84)" />
            </div>
          </div>
          <div className="col-12 mb-3">
            <h6>Abastecimento por {this.state.flight_id !== '0' ? 'Trecho' : 'Dia'}  (USD) {this.getPeriodLabel()}</h6>
            <div className="table-responsive" >
              <Chart width={this.state.data.cost_fueling.length > 12 ? this.state.data.cost_fueling.length * 50 : null} data={this.state.data.cost_fueling} id="cost_fueling" barWidthMin="50" direction="V" currency="$" color="rgb(135, 255, 84)" />
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Custo Fixo (USD / Mês) {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.cost_fixed} id="4" direction="V" currency="$" color="rgb(31, 70, 114)" lighten={false} />
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Custo Variável (USD / Mês) {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.cost_variable} id="5" direction="V" currency="$" color="rgb(31, 70, 114)" lighten={false} />
          </div>
          <div className="col-12 col-sm-4 mb-3">
            <h6>Custo Total (USD / Mês) {this.getPeriodLabel()}</h6>
            <Chart data={this.state.data.cost_total} id="6" direction="V" currency="$" color="rgb(31, 70, 114)" />
          </div>
          {/* <div>
              <CanvasJSChart options={options} onRef={ref => this.chart = ref} />
            </div> */}

          {/* <div className="chart-container">
            <ResponsiveContainer aspect={2}>
              <BarChart width={600} height={300} data={data}
                margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>

                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />


                <Bar dataKey="uv" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>  */}
        </div>

      </React.Fragment >
    );
  }
}

export default withRouter(Dashboard);
