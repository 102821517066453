import React, { Component } from 'react';
import * as common from './Common';

class Chart extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('resize', this.resizeCanvas, false);

    }
    state = {
        data: [],


    }
    componentDidMount() {

    }
    componentDidUpdate() {

        this.resizeCanvas();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeCanvas);
    }
    resizeCanvas = () => {

        var c = document.getElementById("myCanvas_" + this.props.id);
        var ctx = c.getContext("2d");
        var container = document.getElementById("canvasContainer_" + this.props.id);
        //Set chart width
        if (this.props.width) {
            c.width = this.props.width;
            container.style.minWidth = c.width + 'px';
            c.width = container.clientWidth > c.width ? container.clientWidth : c.width;
        }
        else if (this.props.barWidthMin) {
            c.width = (this.props.data.length * (Number(this.props.barWidthMin) + 20));
            container.style.minWidth = c.width + 'px';
            c.width = container.clientWidth > c.width ? container.clientWidth : c.width;
        }
        else
            c.width = container.clientWidth;

        if (!this.props.data || this.props.data.length === 0 || !this.props.data.find(element => element.y && element.y > 0)) {
            ctx.clearRect(0, 0, c.width, c.height);
            return;
        }


        var isVertical = this.props.direction === "V";


        ctx.lineWidth = 1;
        let data = this.props.data;
        let dataSorted = data.slice(0);
        dataSorted.sort((a, b) => b['y'] - a['y']);
        let maxValue = dataSorted[0].y;
        let wStart = isVertical ? 50 : 50
        let hStart = isVertical ? 60 : 40
        let cv = c.height - hStart;
        let cw = c.width - wStart;
        let textColor = 'rgb(28, 28, 28)';
        let lineColor = 'rgb(178, 178, 178)'
        let barWidthMax = 50;
        let barWidthMin = this.props.barWidthMin ? Number(this.props.barWidthMin) : 0;

        let colorIndex = 0;


        //X Line
        ctx.fillStyle = lineColor;
        if (isVertical)
            ctx.fillRect(wStart, c.height - (hStart / 2) - 1, cw - (wStart / 4), 0.5);
        else
            ctx.fillRect((wStart / 2), c.height - (hStart) - 1, cw, 0.5);

        //Y Line
        if (isVertical)
            ctx.fillRect(wStart, hStart / 2, 0.5, cv);

        let fontNormal = '8.5pt Tahoma';
        let fontItalic = 'italic 8.5pt Tahoma';

        ctx.font = fontNormal;
        //maxValue = maxValue == null || maxValue == 0 ? 4 : maxValue; 
        if (maxValue <= 10) {
            if (isVertical) {
                for (let i = maxValue; i >= 0; i--) {
                    let yLine = c.height - (hStart / 2) - ((cv / maxValue) * i);
                    ctx.fillStyle = lineColor;
                    ctx.fillRect(wStart - 5, yLine, cw - (wStart / 4) + 5, 0.5);
                    ctx.textAlign = "right";
                    ctx.fillStyle = textColor;
                    ctx.fillText((this.props.currency || " ") + i, wStart - 7, yLine + 5);
                }
            }
            else {
                for (let i = maxValue; i >= 0; i--) {
                    let xLine = ((cw / maxValue) * i) + (wStart / 2);
                    ctx.fillStyle = lineColor;
                    //ctx.fillRect(xLine, 5, 0.5, cv);
                    ctx.textAlign = "center";
                    ctx.fillStyle = textColor;
                    ctx.fillText(i.toString(), xLine + 5, cv + (hStart / 2));
                }
            }
        } else {

            let strMaxValue = parseInt(maxValue).toString();
            if (maxValue < 100) {
                maxValue = parseInt(strMaxValue)
                proportion = 1;
            }
            else if (maxValue < 1000) {

                maxValue = Number(strMaxValue.substring(0, 2) + "0")
                proportion = 10;
            }
            else if (maxValue < 10000) {
                maxValue = Number(strMaxValue.substring(0, 2) + "00")
                proportion = 100;
            }
            else if (maxValue < 100000) {
                maxValue = Number(strMaxValue.substring(0, 2) + "000")
                proportion = 1000;
            }

            else if (maxValue < 1000000) {
                maxValue = Number(strMaxValue.substring(0, 2) + "0000")
                proportion = 10000;
            }
            let divisor = 0;
            while (true) {
                maxValue += proportion;
                if (maxValue % 6 === 0) { divisor = 6; break; }
                if (maxValue % 4 === 0) { divisor = 4; break; }
            }

            let lineProportion = maxValue / divisor;
            let line = maxValue;
            if (isVertical) {
                for (let i = divisor; i >= 0; i--) {
                    let yLine = c.height - (hStart / 2) - ((cv / maxValue) * line);
                    ctx.fillStyle = lineColor;
                    ctx.fillRect(wStart - 5, yLine, cw - (wStart / 4) + 5, 0.5);
                    ctx.textAlign = "right";
                    ctx.fillStyle = textColor;
                    ctx.fillText((this.props.currency || " ") + this.formatLabel(line), wStart - 7, yLine + 5);
                    line -= lineProportion;
                }
            }
            else {
                for (let i = divisor; i >= 0; i--) {
                    let xLine = ((cw / maxValue) * line) + (wStart / 2);
                    ctx.fillStyle = lineColor;
                    //ctx.fillRect(xLine, 5, 0.5, cv);
                    ctx.textAlign = "right";
                    ctx.fillStyle = textColor;
                    ctx.fillText(line.toString(), xLine + 5, cv + (hStart / 2));
                    line -= lineProportion;
                }
            }

        }
        //Draw Bars
        let colors = ['#7294c9', '#c64f4f', '#91a03e', '#4fc6c0', '#82719e', '#56add8', '#e2944f', '#a37c9d', '#88bf44', '#29486d'];

        var r = 0, g = 0, b = 0;
        if (this.props.color) {
            let rgb = this.props.color.replace('rgb', '').replace('(', '').replace(')', '').split(',');
            r = parseInt(rgb[0].trim()); g = parseInt(rgb[1].trim()); b = parseInt(rgb[2].trim());
        }

        let proportion = 1;
        let maxValueInCanvas = maxValue;
        if (isVertical) { //BARS VERTICAL
            if (maxValue > cv) {
                proportion = maxValue / cv;
                maxValueInCanvas = maxValue / proportion;
            }
            for (let index = 0; index < data.length; index++) {
                data[index].h = data[index].y / proportion;
            }
            let barPosition = wStart + 2.5;
            let barWidth = (cw / data.length) - 5;
            barWidth = barWidth > barWidthMax ? barWidthMax : barWidth;
            barWidth = barWidth < barWidthMin ? barWidthMin : barWidth;

            for (let index = 0; index < data.length; index++) {

                if (!this.props.color)
                    ctx.fillStyle = colors[colorIndex];
                else {
                    ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
                    if (this.props.lighten) {
                        r += 8;
                        g += 8;
                        b += 8;
                    }
                }
                let ySize = (cv / maxValueInCanvas) * data[index].h;

                let y = c.height - (hStart / 2) - ySize
                //Fill Bar
                ctx.fillRect(barPosition, y, barWidth, ySize);

                //Write X Label
                ctx.textAlign = "center";
                ctx.fillStyle = textColor;
                var textOffsetIncrement = 5;
                if (barWidth <= 50 && (data[index].x + "").length > 6)
                    ctx.font = '7pt tahoma'
                if (barWidth < 30 && data.length > 12) {
                    ctx.save();
                    if (barWidth < 30) {
                        ctx.font = '7pt tahoma'
                        var textOffsetIncrement = 4;
                    }
                    ctx.textAlign = "left";
                    ctx.translate(0, 0);
                    ctx.rotate(3 * Math.PI / 2);
                    ctx.fillText(data[index].x, - (c.height - (hStart / 2) - 5), barPosition + (barWidth / 2) + textOffsetIncrement);
                    ctx.restore();
                } else {
                    ctx.fillText(data[index].x, barPosition + (barWidth / 2), c.height - 15);
                    if (data[index].description) {
                        ctx.fillText(data[index].description, barPosition + (barWidth / 2), c.height - 2);
                    }

                }

                ctx.fillStyle = lineColor;
                //Gray bar divisor on X line  
                ctx.fillRect(barPosition + (barWidth / 2), c.height - (hStart / 2) + 1, 0.5, 5);

                barPosition += barWidth + 5;

                colorIndex++;
                if (colorIndex >= colors.length)
                    colorIndex = 0;
            }
            //Write the labels above the bars
            barPosition = wStart + 2.5;
            for (let index = 0; index < data.length; index++) {

                let ySize = (cv / maxValueInCanvas) * data[index].h;
                let y = c.height - (hStart / 2) - ySize

                ctx.textAlign = "center";
                //ctx.fillStyle = "#d4d4d4";
                ctx.fillStyle = textColor;
                ctx.font = '7pt tahoma'
                //Top Bar Label
                if (data[index].y)
                    ctx.fillText((this.props.currency ? "" : "") + this.formatLabel(data[index].y), barPosition + (barWidth / 2), y - 4);
                if (data[index].y_2)
                    ctx.fillText(data[index].y_2, barPosition + (barWidth / 2), y - 15);
                if (data[index].y_3)
                    ctx.fillText(data[index].y_3, barPosition + (barWidth / 2), y - 25);

                barPosition += barWidth + 5;
            }
        }
        else { //BARS HORIZONTAL
            if (maxValue > cw) {
                proportion = maxValue / cw;
                maxValueInCanvas = maxValue / proportion;
            }
            for (let index = 0; index < data.length; index++) {
                data[index].w = data[index].y / proportion;
            }
            let barPosition = 5;
            var barWidth = (cv / data.length) - 5;
            barWidth = barWidth > barWidthMax ? barWidthMax : barWidth;
            for (let index = 0; index < data.length; index++) {

                let xSize = (cw / maxValueInCanvas) * data[index].w;
                let y = c.height - 20 - xSize
                //Fill Bar
                if (data[index].color)
                    ctx.fillStyle = data[index].color;
                else if (!this.props.color)
                    ctx.fillStyle = colors[colorIndex];
                else {
                    ctx.fillStyle = `rgb(${r}, ${g}, ${b})`;
                    if (this.props.lighten) {
                        r += 8;
                        g += 8;
                        b += 8;
                    }
                }
                ctx.fillRect((wStart / 2), barPosition, xSize, barWidth);

                ctx.textAlign = "left";
                //ctx.fillStyle = "#d4d4d4";
                //Write X Label
                ctx.font = 'bold 8.5pt arial'
                ctx.fillStyle = textColor;
                ctx.fillText(data[index].x + ' (' + data[index].y + ')', (wStart / 2) + 5, barPosition + (barWidth / 2) + 5);
                ctx.fillStyle = lineColor;
                //Gray bar divisor on X line  
                //ctx.fillRect(l + (w / 2), c.height - (hStart / 2) + 1, 0.5, 5);

                barPosition += barWidth + 5;
                colorIndex++;
                if (colorIndex >= colors.length)
                    colorIndex = 0;
            }
        }
    }
    buildVertical() {

    }
    formatLabel(num) {
        if (num < 1000)
            return common.formatNumberNoDec(num);
        var label = (num / 1000).toFixed(1).toString().replace(".0", "");
        return label + "K";
    }
    render() {
        return (
            <div id={'canvasContainer_' + this.props.id} className="myCanvas" >
                <canvas id={'myCanvas_' + this.props.id} width="600" height="300"></canvas>
            </div>
        )
    }
}

export default Chart