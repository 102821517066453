import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DateCombo from './DateCombo'
import LogbookPage from './LogbookPage'
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';
import * as common from './Common';
import MyModal from './MyModal';
import Autocomplete from './AutoComplete';

class FlightLeg extends Component {
    constructor(props) {
        super(props);
        this.myLogbookPage = React.createRef();

        common.getData('flight-leg/breadcrumb/' + props.match.params.id).then((data) => {
            this.setState({ parent: data.parent }); this.barList();
        });
        common.getData('flight-passengers/' + props.match.params.id).then((data) => {
            this.setState({ passenger_count: data });
        });
        common.getData('combo/crew').then((data) => { this.setState({ crew: data }) });


    }
    componentDidMount() {
        if (!this.props.match.params.leg_id)
            this.bindList();
        else {
            this.editData(this.props.match.params.leg_id);
        }
        this.getLogbooks();
        common.getData('combo/passenger').then((data) => { this.setState({ passengers: data }) });
        common.getData('combo/airport').then((data) => {

            let cities = Array.from(new Set(data.map(x => x.city))).map(x => { return { text: x } });
            let countries = Array.from(new Set(data.map(x => x.country))).map(x => { return { text: x } });
            let continents = Array.from(new Set(data.map(x => x.continent))).map(x => { return { text: x } });
            this.setState({ airports: data, cities, countries, continents })
        });
    }
    getLogbooks() {
        common.getData('combo/logbook').then((data) => {
            let page = 0;
            let book = 8;
            if (data.length > 0) {
                page = Number(data[0].page);
                book = Number(data[0].book_name);
            }
            if (page == 50) {
                page = 1;
                book++;
            }
            else {
                page++;
            }
            let logbook_new_page = { name: 'LOGBOOK ' + book.toString().padStart(3, '0') + ' Nº ' + page + ' - NOVA PÁG.', id: 0, book_name: book, page: page };
            let logbook_no_page = { name: 'NENHUM', id: -1 };
            data.unshift(logbook_new_page);
            data.unshift(logbook_no_page);
            this.setState({ logbooks: data })
        });
    }
    placeSelected = (field, value) => {

        if (field === 'city') {
            let data = this.state.airports.find(x => x.city === value);
            if (data != null)
                return this.setState({ [field]: value, country: data.country, continent: data.continent });
        }
        else if (field === 'country') {
            let data = this.state.airports.find(x => x.country === value);
            if (data != null)
                return this.setState({ [field]: value, country: data.country, continent: data.continent });
        }
        this.setState({ [field]: value });
    }
    bindList() {
        this.props.show();
        var that = this;
        common.getData('flight-leg/by-flight-id/' + this.props.match.params.id).then((data) => {
            that.props.hide();

            let items = [];
            var legIndex = 1, fuelIndex = 1;
            data.legs.forEach(leg => {
                leg.index = legIndex;
                items.push(leg);
                legIndex++;
                let fuelings = data.fueling.filter(x => x.leg_id === leg.id && !x.added);
                fuelings.forEach(fuel => {
                    fuel.index = fuelIndex;
                    fuel.added = true;
                    items.push(fuel);
                    fuelIndex++;
                });
            });
            let fuelingsWithNoLeg = data.fueling.filter(x => x.leg_id === null || x.leg_id === "0");
            fuelingsWithNoLeg.forEach(fuel => {
                fuel.index = fuelIndex;
                items.push(fuel);
                fuelIndex++;
            });

            this.setState({ items, passengers_default: data.passengers });
        });
        this.getLogbooks();
    }
    async save() {

        this.props.show();
        //Save Logbook
        let logbookSaveResult = await this.myLogbookPage.current.save();
        
        if (!logbookSaveResult) {
            this.setState({ logbookShow: true });
            return this.messageAlert(null);
        }

        let data = this.state.data;
     
        //Get logbook id if new
        if (logbookSaveResult.logbook_id)
            data.logbook_id = logbookSaveResult.logbook_id;
        
        //Validation
        if (!common.isValidDate(data.year + "-" + data.month + "-" + data.day)) return this.messageAlert('Data Decolagem Inválida!');
        if (!common.isValidDate(data.end_year + "-" + data.end_month + "-" + data.end_day)) return this.messageAlert('Data Pouso Inválida!');

        if (!data.f_from || data.f_from === "")
            return this.messageAlert('Preencha o campo From!');

        var times = [{ f: 'f_start', l: 'Start' }, { f: 'f_toff', l: 'T-Off' }, { f: 'f_land', l: 'Land' },
        { f: 'f_sdown', l: 'S-Down' }, { f: 'f_ttime', l: 'T.Time' }, { f: 'f_ftime', l: 'F.Time' }, { f: 'f_day', l: 'Day' },
        { f: 'f_night', l: 'Night' }, { f: 'f_ifr', l: 'IFR' }];

        var messages = '';
        times.forEach(x => {
            if (data[x.f] && data[x.f] !== "__:__") {
                let values = data[x.f].split(":");
                if (isNaN(values[0]) || isNaN(values[1]) || parseInt(values[0]) > 23 || parseInt(values[1]) > 59)
                    messages += "Campo " + x.l + " é inválido!\n";
            }
        })
        if (messages.length > 0) return this.messageAlert(messages);

        // if (!data.f_ttime || data.f_ttime === "__:__" || !data.f_ftime || data.f_ftime === "__:__")
        //     return alert('Os campos T.Time e F.Time estão inválidos!');

        if (data.f_ttime && data.f_ttime !== "__:__" && data.f_ftime && data.f_ftime !== "__:__") {

            let f_ttime_minutes = this.getTimeInMinutes(data.f_ttime);
            let f_ftime_minutes = this.getTimeInMinutes(data.f_ftime);
            if (f_ttime_minutes < f_ftime_minutes)
                return this.messageAlert('F.Time não pode ser maior do que T.Time!');
        }
        //Check whether airport exists
        if (data.f_from && this.state.airports.find(x => x.initials === data.f_from.toUpperCase()) == null) {
            this.props.hide();
            return this.setState({ showModal: true, airport_not_found: data.f_from.toUpperCase() });
        }
        if (data.f_to && this.state.airports.find(x => x.initials === data.f_to.toUpperCase()) == null) {
            this.props.hide();
            return this.setState({ showModal: true, airport_not_found: data.f_to.toUpperCase() });
        }
        this.props.show();
        var that = this;
        data.user_id = common.getUser().id;
        common.postData('flight-leg', data).then(function (data) {
            that.props.hide();
            that.bindList();
            that.back();
        });


    }
    messageAlert(message) {
        if (message)
            alert(message);
        this.props.hide();
        return;
    }
    saveAirport() {
        if (!this.state.city || this.state.city === "") return alert('Informe a Cidade!');
        if (!this.state.country || this.state.country === "") return alert('Informe o País!');
        if (!this.state.continent || this.state.continent === "") return alert('Informe o Continente!');

        let item = {
            initials: this.state.airport_not_found, city: this.state.city,
            country: this.state.country, continent: this.state.continent
        };

        this.props.show();
        common.postData('airport', item).then((data) => {

            if (data === 1) {
                let airports = this.state.airports;
                airports.push(item);
                this.setState({ showModal: false, airports, city: "", country: "", continent: "" })
                setTimeout(() => {
                    this.save();
                }, 1);
            }
            else {
                this.props.hide();
                alert('Houve um erro ao cadastrar o aeroporto!')
            }
        });
    }
    state = {
        items: null,
        fuelingIndex: 1,
        data: this.getNewData(),
        passengers: [],
        passengers_default: [],
        cities: [],
        countries: [],
        continents: [],
        logbooks: [],
        crew: [],

    }
    barList() {
        this.props.changeTitle({ left: <div className="btn-back" onClick={() => { this.props.history.push('/') }}><i className="fas fa-arrow-alt-circle-left"></i> {this.state.parent}</div>, center: 'Trechos', right: <button type="button" onClick={() => this.newData()} className="btn-right" hidden={common.isCliente()} >Novo</button> });
    }
    barForm = () => {
        this.props.changeTitle({ left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>, center: 'Trecho' });
    }
    back() {
        if (!this.props.match.params.leg_id) {
            this.barList();
            document.getElementById('new').className = 'form go';
            document.getElementById('list').className = '';
        }
        else {
            window.history.back();
        }

    }
    handleChange = e => {

        if (e.target.name === "continent")
            return this.setState({ [e.target.name]: e.target.value });

        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({ data })

        if (e.target.name == 'logbook_id') {
            this.myLogbookPage.current.logbookIdChanged(e.target.value);
        }

    }
    newData() {
        this.setState({ data: this.getNewData(), logbookShow: true });
        document.getElementById('new').className = 'form come';
        document.getElementById('list').className = 'hidden';
        this.barForm();

        //Reset logbook data
        this.myLogbookPage.current.logbookIdChanged(0);

    }
    editData = (id) => {
        this.props.show();
        common.getData('flight-leg/' + id).then((data) => {
            this.props.hide();
            let date_start = common.checkDate(data.date_start_view);
            data.year = date_start.getFullYear();
            data.month = date_start.getMonth() + 1;
            data.day = date_start.getDate();
            let date_end = common.checkDate(data.date_end_view);
            data.end_year = date_end.getFullYear();
            data.end_month = date_end.getMonth() + 1;
            data.end_day = date_end.getDate();
            let date_closing = common.checkDate(data.date_signed_view);
            data.closing_year = date_closing.getFullYear();
            data.closing_month = date_closing.getMonth() + 1;
            data.closing_day = date_closing.getDate();
            
            data.passenger = 0;
            if (!data.logbook_id)
                data.logbook_id = 0;
            this.setState({ data: data, logbookShow: false })
            document.getElementById('new').className = 'form come';
            document.getElementById('list').className = 'hidden';

            this.barForm();

            //Get logbook data
            this.myLogbookPage.current.logbookIdChanged(data.logbook_id);
        });
    }
    getNewData() {

        let f_from = '';
        if (this.state) {
            let legs = this.state.items.filter(x => !x.date_fueling_view);
            if (legs.length > 0)
                f_from = legs[legs.length - 1].f_to;
        }

        return {
            id: 0,
            flight_id: this.props.match.params.id,
            signature: this.state ? this.state.crew[0].id : 1,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
            end_year: new Date().getFullYear(),
            end_month: new Date().getMonth() + 1,
            end_day: new Date().getDate(),
            f_pax: this.state ? this.state.passenger_count : "",
            passengers: this.state ? this.state.passengers_default : [],
            passenger: 0,
            logbook_id: 0,
            f_from: f_from,
            closing_year: new Date().getFullYear(),
            closing_month: new Date().getMonth() + 1,
            closing_day: new Date().getDate(),
            f_lds : 1,
            f_rcycles : 1,
            f_lcycles : 1,
        }
    }
    addPassenger() {
        let data = this.state.data;
        if (data.passenger == "-1") {
            this.addPassengerOther();
            return;
        }
        let newItem = this.state.passengers[this.state.data.passenger];
        let p = data.passengers.filter(x => { return parseInt(x.id) === parseInt(newItem.id) });

        if (p.length > 0) return alert('Este passageiro já foi adicionado!')
        data.passengers.push(newItem);
        data.f_pax = data.passengers.length;
        this.setState({ data });
    }
    addPassengerOther() {
        let name = document.getElementById('passenger_other').value.trim();
        if (!name || name === "") return alert('Digite o nome do novo passageiro!')
        let data = this.state.data;
        let p = data.passengers.filter(x => { return x.name === name });
        if (p.length > 0) return alert('Este passageiro já foi adicionado!')
        data.passengers.push({ id: name, name: name });
        data.passenger = 0;
        this.setState({ data });
        document.getElementById('passenger_other').value = '';
    }
    removePassenger(id) {
        let data = this.state.data;
        for (let i = 0; i < data.passengers.length; i++) {
            if (data.passengers[i].id == id) {
                data.passengers.splice(i, 1);
                break;
            }
        }
        data.f_pax = data.passengers.length;
        this.setState({ data });
    }
    deleteLeg = (x, e) => {
        e.stopPropagation();

        if (!window.confirm('Deseja excluir este trecho?'))
            return;

        this.props.show();
        var that = this;
        common.postData('flight-leg/delete-leg', x).then(function (data) {
            that.props.hide();
            if (data.error) {
                return alert(data.error);
            }
            that.bindList();
        });
    }
    deleteFuel = (x, e) => {
        e.stopPropagation();

        if (!window.confirm('Deseja excluir este abastecimento?'))
            return;

        this.props.show();
        var that = this;
        common.postData('flight-fueling/delete-fuel', x).then(function (data) {
            that.props.hide();
            if (data.error) {
                return alert(data.error);
            }
            that.bindList();
        });
    }
    render() {

        return (
            <React.Fragment>
                <MyModal handleShow={this.state.showModal} handleClose={() => { this.setState({ showModal: false }) }} title="Aeroporto" >
                    <div>
                        <div className="mb-3">
                            <b>O aerporto <b className="text-info">{this.state.airport_not_found}</b> não foi encontrado! Cadastre-o abaixo:</b>
                        </div>
                        <div>
                            <div className="label">Cidade</div>
                            <Autocomplete className="form-control" onSelect={(text) => { this.placeSelected('city', text) }} data={this.state.cities} placeholder="Cidade..." value={this.state.city} />
                        </div>
                        <div className="label">
                            <div>País</div>
                            <Autocomplete className="form-control" onSelect={(text) => { this.placeSelected('country', text) }} data={this.state.countries} placeholder="País..." value={this.state.country} />
                        </div>
                        <div className="label">
                            <div>Continente</div>
                            <select className="form-control" name="continent" value={this.state.continent} onChange={this.handleChange} >
                                <option value="" >Continentes</option>
                                {this.state.continents.map((x, i) => <option key={i} value={x.text} >{x.text}</option>)}
                            </select>
                        </div>
                        <div className="mt-2 text-right">
                            <button disabled={this.props.isLoading} className="btn btn-primary" onClick={this.saveAirport.bind(this)} >Salvar</button>
                        </div>
                    </div>
                </MyModal>
                <div id="list">

                    {(!this.state.items) ? <div></div> :
                        this.state.items.length === 0 ? <div className="bg-cream rounded text-center p-2">- Ainda não há nenhuma trecho cadastrado</div> :
                            <div>
                                {this.state.items.map((x, i) => <React.Fragment key={i} >
                                    {!x.date_fueling_view ? //LEG
                                        <div className={'row no-gutters position-relative mb-1 rounded bg-cream-dark pointer'} onClick={this.editData.bind(this, x.id)} >

                                            <div className="col-1 bg-blue text-center" onClick={common.isCliente() ? null : this.deleteLeg.bind(this, x)} >
                                                <div className="v-align">{x.index}
                                                    <div hidden={common.isCliente() || x.expense_count > 0}  ><i className="fas fa-trash-alt font-sm" ></i></div>
                                                </div>
                                            </div>
                                            <div className="col p-1 pt-0">
                                                <div className="mb-1 font-xs">{x.date_start_view} - {x.date_end_view}</div>
                                                <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.f_from}</div>
                                                <i className="fas fa-arrow-circle-right ml-1 mr-1"></i>
                                                <div className="badge badge-success" style={{ width: 60, fontSize: 12 }} >{x.f_to}</div>
                                                <div className="font-sm mt-1">
                                                    <div className="row no-gutters">
                                                        <div className="col-6" ><b><i className="fas fa-coins"></i> Despesas</b> : {x.expense_count || 0}</div>
                                                        <div className="col-6" ><b>Total: </b>
                                                            <b className={parseFloat(x.amount_dollar_min) === 0 ? 'text-danger' : 'text-green'}>${common.formatNumber(x.expense_amount)}</b>
                                                        </div>
                                                        <div className="col-6"><b><i className="fas fa-plane"></i> Tempo: </b>{x.f_ftime ? x.f_ftime.substring(0, 5) : 'Não informado'}</div>
                                                        <div className="col-6" hidden={!x.logbook_name}><b>Logbook: </b><a onClick={(e) => e.stopPropagation()} href={common.api_url + 'shared/pdf-page/' + x.logbook_id} target="_blank" >{x.logbook_name}</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2 bg-blue font-sm text-center " onClick={(e) => { e.stopPropagation(); this.props.history.push('/leg-expense/' + x.flight_id + '/' + x.id) }} >
                                                <div className="v-align"  >
                                                    <i className="fas fa-credit-card mr-1"></i><br />Desp.
                                                 </div>
                                            </div>
                                        </div>
                                        : //FUELING
                                        <div className={'row no-gutters position-relative mb-1 rounded bg-cream-dark pointer font-sm'} onClick={() => { this.props.history.push('/flight-fueling/' + this.props.match.params.id + '/' + x.id) }} >

                                            <div className="col-1 bg-success text-center text-white" onClick={common.isCliente() ? null : this.deleteFuel.bind(this, x)} >
                                                <div className="v-align">{x.index}
                                                    <div hidden={common.isCliente()}  ><i className="fas fa-trash-alt font-sm" ></i></div>
                                                </div>
                                            </div>
                                            <div className="col p-1 pt-0">
                                                <div className="row no-gutters">
                                                    <div className="col-12" hidden={x.leg_id !== '0'} >
                                                        <div className="badge badge-danger mb-1" style={{ fontSize: 12 }} >* Sem trecho associado!</div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.station}</div>
                                                    </div>
                                                    <div className="col-6">{x.date_fueling_view}</div>
                                                    <div className="w-100 mb-1" ></div>
                                                    <div className="col-6" ><b>Galões: </b>{common.formatNumberNoDec(x.gal)}</div>
                                                    <div className="col-6" ><b>Lts:</b> {common.formatNumberNoDec(x.lts)}</div>
                                                    <div className="w-100" ></div>
                                                    <div className="col-6" ><b>Broker:</b> {x.broker || ''}</div>
                                                    <div className="col-6" ><b>Provider:</b> {x.provider || ''}</div>
                                                </div>
                                            </div>
                                            <div className="col-2 font-sm bg-success text-white text-center" >
                                                <div className="v-align">Fueling</div>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                                )}
                                <div className="mt-2 text-right" >
                                    <button type="button" onClick={() => { this.props.history.push('/flight-fueling/' + this.props.match.params.id + '/0') }} className="btn btn-sm btn-success" hidden={common.isCliente()} >Novo Abastecimento</button>
                                </div>
                            </div>

                    }

                </div>
                <div id="new" className="form">
                    <div className="row no-gutters" >
                        <div className="col-12 col-sm-6">
                            <div className="row no-gutters" >
                                <div className="col" >
                                    <div className="label">Decolagem</div>
                                    <DateCombo year={this.state.data.year} month={this.state.data.month} day={this.state.data.day} onChange={this.handleChange} />
                                </div>
                                <div className="col" >
                                    <div className="label">Pouso</div>
                                    <DateCombo namePrefix="end_" year={this.state.data.end_year} month={this.state.data.end_month} day={this.state.data.end_day} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="label">Logbook</div>
                            <select className="form-control" name="logbook_id" value={this.state.data && this.state.data.logbook_id + ""} onChange={this.handleChange} >
                                {this.state.logbooks.map((x, i) => <option key={i} value={x.id} >{x.name}</option>)}
                            </select>
                        </div>
                        <div className="col-12 pt-3" >
                            <b>LOGBOOK</b>
                            <div className="bg-secondary p-2 rounded" >
                                <LogbookPage ref={this.myLogbookPage} legData={this.state.data} logbooks={this.state.logbooks}></LogbookPage>
                            </div>
                        </div>
                        <div className="w-100" ></div>
                        {this.getControl('From', 'f_from', "", { textTransform: 'uppercase' })}
                        {this.getControl('To', 'f_to', "", { textTransform: 'uppercase' })}
                        <div className="w-100" ></div>
                        {this.getControl('Start', 'f_start', "99:99")}
                        {this.getControl('T-Off', 'f_toff', "99:99")}
                        <div className="w-100" ></div>
                        {this.getControl('Land', 'f_land', "99:99")}
                        {this.getControl('S-Down', 'f_sdown', "99:99")}
                        <div className="w-100" ></div>
                        {this.getControl('T.Time', 'f_ttime', "99:99")}
                        {this.getControl('F.Time', 'f_ftime', "99:99")}
                        <div className="w-100" ></div>
                        {this.getControl('Day', 'f_day', "99:99")}
                        {this.getControl('Night', 'f_night', "99:99")}
                        {this.getControl('IFR', 'f_ifr', "99:99")}
                        <div className="w-100" ></div>
                        {this.getControlNumber('Initial', 'f_initial', false)}
                        {this.getControlNumber('Used', 'f_used', false)}
                        {this.getControlNumber('Rem', 'f_rem', true)}
                        <div className="w-100" ></div>
                        {this.getControlNumber('Miles', 'f_miles')}
                        {this.getControl('Req By', 'f_reqby')}
                        {this.getControl('Pax', 'f_pax')}
                        <div className="w-100" ></div>
                        {this.getControlNumber('LDS', 'f_lds')}
                        {this.getControlNumber('L Cycles', 'f_lcycles')}
                        {this.getControlNumber('R Cycles', 'f_rcycles')}
                        <div className="col-12">
                            <div hidden={common.isCliente()} >
                                <div className="label">Passageiros</div>
                                <select className="form-control select-button" name="passenger" value={this.state.data.passenger} onChange={this.handleChange} >
                                    {this.state.passengers.map((x, i) => <option key={x.id} value={i} >{x.name}</option>)}
                                    <option value="-1" >Outro</option>
                                </select>
                                <button className="btn btn-primary" style={{ width: 100 }} onClick={() => this.addPassenger()} >Adicionar</button>
                            </div>
                            <div hidden={this.state.data.passenger != "-1"}>
                                <input type="text" placeholder="Nome..." className="form-control select-button mt-1" id="passenger_other"  ></input>
                            </div>
                            <div className="mt-2 rounded">
                                {this.state.data.passengers.map((x, i) => <div className="bg-list" key={x.id} >{i + 1}) {x.name}  <i onClick={() => this.removePassenger(x.id)} className="fas fa-trash-alt float-right mr-2 mt-1" ></i> </div>)}
                            </div>
                        </div>
                        <div className="col-6" >
                            <div className="label">Fechamento</div>
                            <DateCombo namePrefix="closing_" year={this.state.data.closing_year} month={this.state.data.closing_month} day={this.state.data.closing_day} onChange={this.handleChange} />
                        </div>
                        <div className="col-6" >
                            <div className="label">Assinatura</div>
                            <select className="form-control" name="signature" value={this.state.data.signature || ''} onChange={this.handleChange} >
                                {this.state.crew.map((x, i) => <option key={x.id} value={x.id} >{x.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="text-right pt-3">
                        <button className="btn btn-main" onClick={() => this.save()} hidden={common.isCliente()} >Salvar</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    getControl = (label, field, mask, css) => {
        let mode = (field !== "f_from" && field !== "f_to" && field !== "f_reqby" && field !== "f_pax") ? "tel" : "text";
        return (
            <div className="col mr-1">
                <div className="label">{label}</div>
                <InputMask mask={mask || ""} type={mode} next="1" autoComplete={field === "f_from" || field === "f_to" ? "on" : "off"} alwaysShowMask="true" disabled={field === "f_ttime" || field === "f_ftime"} onKeyUp={this.setTotalTime} style={css} className="form-control" name={field} value={this.state.data[field] || ""} onChange={this.handleChange}  ></InputMask>
            </div>
        )
    }
    getControlNumber = (label, field, disabled) => {
        return (
            <div className="col mr-1">
                <div className="label">{label}</div>
                <CurrencyFormat type="tel" value={this.state.data[field] || ""} disabled={disabled} className="form-control" onKeyUp={this.setFuelRemaining} name={field} thousandSeparator={'.'} decimalSeparator="," onChange={this.handleChange} />
            </div>
        )
    }
    getTimeInMinutes(value) {
        let hour = (value || '__:__').toString();
        let hour_nums = hour.split(':').map(x => Number(x));
        if (isNaN(hour_nums[0]) || isNaN(hour_nums[1]) || isNaN(hour_nums[0]) || isNaN(hour_nums[1]))
            return 0;
        return (hour_nums[0] * 60) + hour_nums[1]
    }
    setTotalTime = (e) => {
        let data = this.state.data;

        if (e.target.name == 'f_sdown' || e.target.name == 'f_start') {
            let f_sdown = (this.state.data.f_sdown || '__:__').toString();
            let f_start = (this.state.data.f_start || '__:__').toString();
            let f_sdown_nums = f_sdown.split(':').map(x => Number(x));
            let f_start_nums = f_start.split(':').map(x => Number(x));
            if (isNaN(f_sdown_nums[0]) || isNaN(f_sdown_nums[1]) || isNaN(f_start_nums[0]) || isNaN(f_start_nums[1])) {
                data.f_ttime = '__:__';
            }
            else {
                if (f_sdown_nums[0] < f_start_nums[0]) f_sdown_nums[0] += 24;
                var f_sdown_mins = (f_sdown_nums[0] * 60) + f_sdown_nums[1];
                var f_start_mins = (f_start_nums[0] * 60) + f_start_nums[1];
                let total_min = f_sdown_mins - f_start_mins;
                data.f_ttime = this.setMinutesToHour(total_min);
            }
        }
        else if (e.target.name == 'f_land' || e.target.name == 'f_toff') {
            let f_land = (this.state.data.f_land || '__:__').toString();
            let f_toff = (this.state.data.f_toff || '__:__').toString();
            let f_land_nums = f_land.split(':').map(x => Number(x));
            let f_toff_nums = f_toff.split(':').map(x => Number(x));
            if (isNaN(f_land_nums[0]) || isNaN(f_land_nums[1]) || isNaN(f_toff_nums[0]) || isNaN(f_toff_nums[1])) {
                data.f_day = data.f_night = data.f_ftime = '__:__';
            }
            else {
                if (f_land_nums[0] < f_toff_nums[0]) f_land_nums[0] += 24;
                var f_land_mins = (f_land_nums[0] * 60) + f_land_nums[1];
                var f_toff_mins = (f_toff_nums[0] * 60) + f_toff_nums[1];
                let total_min = f_land_mins - f_toff_mins;
                data.f_ftime = this.setMinutesToHour(total_min);
            }
        }
        //Set Day Night Time
        let f_ftime = this.getTimeInMinutes(data.f_ftime);
        if (f_ftime === 0) {
            data.f_day = data.f_night = '__:__';
        }
        else {
            let f_day = this.getTimeInMinutes(data.f_day);
            let f_night = this.getTimeInMinutes(data.f_night);
            if (f_day !== 0 || f_night !== 0) {
                if (e.target.name === 'f_day') {
                    var night_minutes = f_ftime - f_day;
                    data.f_night = this.setMinutesToHour(night_minutes);
                }
                else if (e.target.name === 'f_night') {
                    var day_minutes = f_ftime - f_night;
                    data.f_day = this.setMinutesToHour(day_minutes);
                }
            }
        }
        this.setState({ data });

        this.changeFocus(e);

    }
    setMinutesToHour = (value) => {
        let hour = parseInt(value / 60);
        let minutes = value % 60;
        return ("0" + hour).slice(-2) + ":" + ("0" + minutes).slice(-2);
    }
    setFuelRemaining = (e) => {
        let data = this.state.data;
        let rem = (this.state.data.f_initial || 0).toString().replace(/\./g, '') - (this.state.data.f_used || 0).toString().replace(/\./g, '');
        data.f_rem = rem;
        this.setState({ data });



    }
    changeFocus = (e) => {
        let name = e.target.name;
        var id = null;
        if (e.target.value.indexOf("_") < 0 && e.target.value.length === 4 && (name === "f_from" || name === "f_to")) {
            if (name === "f_from") id = "f_to";
            else if (name === "f_to") id = "f_start";
        }
        else if (e.target.value.indexOf("_") < 0 && e.target.value.length === 5) {
            if (name === "f_from") id = "f_to";
            else if (name === "f_to") id = "f_start";
            else if (name === "f_start") id = "f_toff";
            else if (name === "f_toff") id = "f_land";
            else if (name === "f_land") id = "f_sdown";
            else if (name === "f_sdown") id = "f_day";
            else if (name === "f_day") id = "f_night";
            else if (name === "f_night") id = "f_ifr";
            else if (name === "f_ifr") id = "f_initial";
        }
        if (id != null) { document.getElementsByName(id)[0].focus() };

    }
}

export default withRouter(FlightLeg)