import React, { Component } from 'react'

class AutoComplete extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this._ismounted = true;
        this.setState({ data: this.props.data });
    }
    componentWillUnmount() {
        console.log("S");
        this._ismounted = false;
    }
    state = {
        autocompleteShow: false,
        data: [],
    }

    onChange = (e) => {

        if (e.target.value.length < 1) {
            this.setState({ autocompleteShow: false })
            this.props.onSelect(e.target.value);
            return;
        }
        let data = [];
        let dataList = this.props.data;

        for (let x of dataList) {
            x.class = null;
            let startIndex = (x.text + "").toUpperCase().indexOf(e.target.value.toUpperCase());
            if (startIndex >= 0) {
                let text = x.text.substr(startIndex, e.target.value.length);
                x.li = x.text.replace(new RegExp(e.target.value, 'i'), "<b class=\"match\" >" + text + "</b>")
                data.push({ ...x });
                if (data.length === 10)
                    break;
            }
        }
        this.setState({ data, autocompleteShow: true, scrollIndex: -1 })
        this.props.onSelect(e.target.value);
    }
    handleKeyUp = (e) => {

        if (e.keyCode === 13) {
            if (this.state.data.length === 1) {
                this.setState({ autocompleteShow: false, scrollIndex: -1 });
                this.props.onSelect(this.state.data[0].text);
            }
            else if (this.state.scrollIndex > -1) {
                this.props.onSelect(this.state.data[this.state.scrollIndex].text);
                this.setState({ autocompleteShow: false, scrollIndex: -1 });
            }
        }
    }
    handleClick = (item, e) => {

        this.props.onSelect(item.text);
        this.setState({ autocompleteShow: false, scrollIndex: -1 });

    }
    handleBlur = (e) => {

        setTimeout(() => {
    
            if (!this._ismounted)
                return;
            this.setState({ autocompleteShow: false, scrollIndex: -1 })
        }, 500);

    }
    handleKeyDown = (e) => {

        if (e.keyCode == 40 || e.keyCode == 38) {

            if (!this.state.data || this.state.data.length === 0)
                return;

            if (e.keyCode == 38 && this.state.scrollIndex <= 0) return;
            if (e.keyCode == 40 && this.state.scrollIndex >= this.state.data.length - 1) return;

            let scrollIndex = e.keyCode == 40 ? this.state.scrollIndex + 1 : this.state.scrollIndex - 1;

            let data = this.state.data;
            data.forEach((x) => { x.class = null; })
            data[scrollIndex].class = "li-hover";

            this.setState({ data, scrollIndex });
            this.props.onSelect(data[scrollIndex].text);
        }
    }

    render() {
        return (
            <div className="autocomplete-container">
                <input type="text" className={this.props.className} placeholder={this.props.placeholder} value={this.props.value || ""}
                    onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} onChange={this.onChange} onBlur={this.handleBlur}
                />
                <div className="autocomplete" hidden={!this.state.autocompleteShow}>
                    {this.state.data.map((x, i) =>
                        <li key={i} className={x.class} onClick={this.handleClick.bind(this, x)} dangerouslySetInnerHTML={{ __html: x.li }} ></li>
                    )}
                </div>
            </div>
        )
    }
}
export default AutoComplete