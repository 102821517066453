import React, { Component } from 'react';
import * as common from './Common';
import InputMask from 'react-input-mask';
import DateCombo from './DateCombo'


class LogbookPage extends Component {
  constructor(props) {
    super(props);

    this.bindList();
    common.getData('combo/crew').then((data) => { this.setState({ crew: data }) });

  }
  state = {
    items: [],
    itemsAll: [],
    sortField: '',
    filter: '',
    expense_types: [],
    data: this.getNewData(),
    crew: [],
    crew_types: [{ id: 'PIC', name: 'PIC' }, { id: 'SIC', name: 'SIC' }]
  }
  bindList() {
    var that = this;
    common.getData('logbook').then((data) => { this.setState({ items: data, itemsAll: data }) })
  }
  newData() {
    common.scrollTop();
    this.setState({ data: this.getNewData(), logbookShow: false });
  }
  logbookIdChanged(id) {
    if (!id || id == 0 || id == -1)
      this.newData();
    else
      this.editData(id);
  }
  editData(id) {

    common.getData('logbook/' + id).then((data) => {
      data.active = data.active === '1';
      data.crew_index = 0;
      data.crew_report_data = {};
      data.backwork_data = {};
      data.date_logbook = data.date_logbook_view;


      let logbookShow = false;
      if (data.crew_report.length > 0 || data.backwork.length > 0 || (data.notes != null && data.notes != '') || (data.aircraft_status != null && data.aircraft_status != ''))

        logbookShow = true;
      this.setState({ data: data, logbookShow })
    });
  }
  save() {

    // if (!this.state.hasDataChange && this.props.legData.logbook_id > 0)
    //   return true;

    if (this.props.legData.logbook_id == -1)
      return true;

    let data = this.state.data;
    if (data.crew.length == 0) {
      alert('Informe os tripulantes');
      return false;
    }

    data.crew.forEach(x => {
      if (!x.type_id) x.type_id = 'PIC';
    })
    if (!data.crew.find(x => x.type_id == 'PIC')) {
      alert('Selecione o tripulante PIC');
      return false;
    }
    if (!data.crew.find(x => x.type_id == 'SIC')) {
      alert('Selecione o tripulante SIC');
      return false;
    }

    let logbook = this.props.logbooks.find(x => x.id == this.props.legData.logbook_id);
    data.id = this.props.legData.logbook_id;
    if (logbook) {
      data.page = logbook.page;
      data.book_name = logbook.book_name;
    }
    data.signature = this.props.legData.signature;
    data.date_signed = this.props.legData.closing_year + '-' + this.props.legData.closing_month + '-' + this.props.legData.closing_day;
    data.date_logbook = this.props.legData.year + '-' + this.props.legData.month + '-' + this.props.legData.day;
    data.user_id = common.getUser().id;
    return common.postData('logbook', data);
  }
  getNewData() {

    return {
      id: 0,
      crew_index: 0,
      crew: this.state ? this.state.crew.filter(x => x.id < 3) : [],
      crew_report: [],
      crew_report_data: { date_report: common.newDate() },
      backwork: [],
      backwork_data: { date_backwork: common.newDate() },
      date_logbook: common.newDate(),
      page: this.state && this.state.itemsAll.length > 0 ? Number(this.state.items.sort((a, b) => b.page - a.page)[0].page) + 1 : '',

    }
  }
  handleChange = e => {
    let data = this.state.data;
    if (e.target.type === 'checkbox')
      data[e.target.name] = e.target.checked ? 1 : 0;
    else
      data[e.target.name] = e.target.value;

    this.setState({ data, hasDataChange: true })

  }
  // CREW REPORT 
  handleChangeCrewReport = e => {
    let data = this.state.data;
    data.crew_report_data[e.target.name] = e.target.value;
    this.setState({ data })

  }
  addCrewReport = () => {

    if (!common.isValidDate(this.state.data.crew_report_data.date_report, true))
      return alert('Data inválida!');

    let x = this.state.data.crew_report_data;
    let messages = [];
    if (!x.date_report || x.date_report == '')
      messages.push('Preencha a data de Crew Report');
    if (!x.system_name || x.system_name == '')
      messages.push('Preencha o System Name de Crew Report');
    if (!x.discrepancy || x.discrepancy == '')
      messages.push('Preencha o Discrepancy de Crew Report');
    if (!x.faa || x.faa == '')
      messages.push('Preencha o FAA de Crew Report');
    if (!x.sign || x.sign == '')
      messages.push('Preencha o Sign de Crew Report');
    if (messages.length > 0)
      return alert(messages.join("\n"));

    this.state.data.crew_report.push(this.state.data.crew_report_data);
    this.state.data.crew_report_data = {};
    this.setState({ data: this.state.data, hasDataChange: true });
  }
  removeCrewReport = (index) => {
    this.state.data.crew_report.splice(index, 1);
    this.setState({ data: this.state.data, hasDataChange: true });

  }
  // BACKWORK 
  handleChangeBackwork = e => {
    let data = this.state.data;
    data.backwork_data[e.target.name] = e.target.value;
    this.setState({ data });

  }
  addBackwork = () => {

    if (!common.isValidDate(this.state.data.backwork_data.date_backwork, true))
      return alert('Data inválida!');

    let x = this.state.data.backwork_data;
    let messages = [];
    if (!x.date_backwork || x.date_backwork == '')
      messages.push('Preencha a data de Backwork');
    if (!x.retification || x.retification == '')
      messages.push('Preencha o Retification de Backwork');
    if (!x.faa || x.faa == '')
      messages.push('Preencha o FAA de Backwork');
    if (!x.sign || x.sign == '')
      messages.push('Preencha o Sign de Backwork');

    if (messages.length > 0)
      return alert(messages.join("\n"));

    this.state.data.backwork.push(this.state.data.backwork_data);
    this.state.data.backwork_data = {};
    this.setState({ data: this.state.data, hasDataChange: true });
  }
  removeBackwork = (index) => {
    this.state.data.backwork.splice(index, 1);
    this.setState({ data: this.state.data, hasDataChange: true });

  }
  filter(e) {
    let items = [];
    if (e.target.value === '')
      items = this.state.itemsAll;
    else
      items = this.state.itemsAll.filter(x => x.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0);
    this.setState({ items });
  }
  addCrew() {
    let data = this.state.data;
    let newItem = this.state.crew[this.state.data.crew_index];
    if (data.crew.length > 0) {
      let p = data.crew.filter(x => { return parseInt(x.id) === parseInt(newItem.id) });
      if (p.length > 0) return alert('Este tripulante já foi adicionado!')
    }
    data.crew.push(newItem);
    this.setState({ data });
  }
  removeCrew(id) {
    let data = this.state.data;
    for (let i = 0; i < data.crew.length; i++) {
      if (data.crew[i].id == id) {
        data.crew.splice(i, 1);
        break;
      }
    }
    this.setState({ data });
  }
  showPDF = (item) => {
    this.props.show();
    common.getData('shared/pdf-page' + item.id).then((data) => {
      this.props.hide();
    });
  }
  handleChangeCrew = (item, e) => {

    item.type_id = e.target.value;
    this.setState({ data: this.state.data, hasDataChange: true });
  }
  render() {

    return (
      <React.Fragment>
        <div>
          {/* CREW */}
          <div className="tab">
            <div className="p-1 row no-gutters">
              <div className="col"><b style={{ fontSize: 16 }}>Tripulação</b></div>
              <div className="col-auto"><button className="btn btn-sm btn-primary" onClick={() => this.addCrew()} ><i className="fas fa-plus"></i></button></div>
            </div>
            <select className="form-control" name="crew_index" value={this.state.data.crew_index} onChange={this.handleChange} >
              {this.state.crew.map((x, i) => <option key={x.id} value={i} >{x.name}</option>)}
            </select>
            <div className="mt-2 rounded">
              {this.state.data.crew.map((x, i) =>
                <div className="bg-list-alternate row no-gutters align-items-center" key={x.id} >
                  <div className="col-6">{i + 1}) {x.name}</div>
                  <div className="col-4">
                    <select className="form-control form-control-sm" name="crew_type_id" value={x.type_id || 'PIC'} onChange={this.handleChangeCrew.bind(this, x)} >
                      {this.state.crew_types.map((y, i) => <option key={y.id} value={i.id} >{y.name}</option>)}
                    </select>
                  </div>
                  <div className="col-2 text-right" >
                    <i onClick={() => this.removeCrew(x.id)} className="fas fa-trash-alt" ></i>
                  </div>
                </div>)}
            </div>
          </div>

          <button type="button" className="btn btn-sm btn-primary mb-2 mt-2" onClick={() => { this.setState({ logbookShow: !this.state.logbookShow }) }} >Editar Mais Informações</button>
          <div hidden={!this.state.logbookShow}  >
            <div className="row no-gutters" >
              <div className="col-12 p-1" >
                <div className="label">Notes</div>
                <input type="text" placeholder="Notes" className="form-control" name="notes" value={this.state.data.notes || ""} onChange={this.handleChange}  ></input>
              </div>
              <div className="col-12 p-1" >
                <div className="label">Aircraft Status</div>
                <input type="text" placeholder="Aircraft Status" className="form-control" name="aircraft_status" value={this.state.data.aircraft_status || ""} onChange={this.handleChange}  ></input>
              </div>
            </div>

            {/* CREW REPORT */}
            <div className="tab">
              <div className="p-1 row no-gutters">
                <div className="col"><b style={{ fontSize: 16 }}>Crew Report</b></div>
                <div className="col-auto"><button className="btn btn-sm btn-primary" onClick={() => this.addCrewReport()} ><i className="fas fa-plus"></i></button></div>
              </div>
              <div className="row no-gutters">
                <div className="col-12 col-md-2 p-1"><InputMask mask={'99/99/9999'} type="text" name="date_report" placeholder="Date" value={this.state.data.crew_report_data.date_report || ''} className="form-control form-control-sm" onChange={this.handleChangeCrewReport} ></InputMask></div>
                <div className="col-6 col-md-3 p-1"><input type="text" name="system_name" placeholder="System" value={this.state.data.crew_report_data.system_name || ''} className="form-control form-control-sm" onChange={this.handleChangeCrewReport} ></input></div>
                <div className="col-6 col-md-3 p-1"><input type="text" name="discrepancy" placeholder="Discrepancy" value={this.state.data.crew_report_data.discrepancy || ''} className="form-control form-control-sm" onChange={this.handleChangeCrewReport} ></input></div>
                <div className="col-6 col-md-2 p-1"><input type="text" name="faa" placeholder="FAA" value={this.state.data.crew_report_data.faa || ''} className="form-control form-control-sm" onChange={this.handleChangeCrewReport} ></input></div>
                <div className="col-6 col-md-2 p-1"><input type="text" name="sign" placeholder="Sign" value={this.state.data.crew_report_data.sign || ''} className="form-control form-control-sm" onChange={this.handleChangeCrewReport} ></input></div>
              </div>
              <div hidden={this.state.data.crew_report.length == 0} className="table-responsive">
                <table className="table table-sm table-light table-striped">
                  <thead>
                    <tr>
                      <th>Date</th><th>System</th><th>Discrepancy</th><th>FAA</th><th>Sign</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.crew_report.map((x, i) => <tr key={i}>
                      <td>{x.date_report_view || x.date_report}</td><td>{x.system_name}</td><td>{x.discrepancy}</td><td>{x.faa}</td><td>{x.sign}</td>
                      <td><i onClick={() => this.removeCrewReport(i)} className="fas fa-trash-alt" ></i></td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
            {/* BACK WORK */}
            <div className="tab">
              <div className="p-1 row no-gutters">
                <div className="col"><b style={{ fontSize: 16 }}>Back Work</b></div>
                <div className="col-auto"><button className="btn btn-sm btn-primary" onClick={() => this.addBackwork()} ><i className="fas fa-plus"></i></button></div>
              </div>
              <div className="row no-gutters">
                <div className="col-12 col-md-2 p-1"><InputMask mask={'99/99/9999'} type="text" name="date_backwork" placeholder="Date" value={this.state.data.backwork_data.date_backwork || ''} className="form-control form-control-sm" onChange={this.handleChangeBackwork} ></InputMask></div>
                <div className="col-6 col-md-3 p-1"><input type="text" name="retification" placeholder="Retification" value={this.state.data.backwork_data.retification || ''} className="form-control form-control-sm" onChange={this.handleChangeBackwork} ></input></div>
                <div className="col-6 col-md-2 p-1"><input type="text" name="faa" placeholder="FAA" value={this.state.data.backwork_data.faa || ''} className="form-control form-control-sm" onChange={this.handleChangeBackwork} ></input></div>
                <div className="col-6 col-md-2 p-1"><input type="text" name="sign" placeholder="Sign" value={this.state.data.backwork_data.sign || ''} className="form-control form-control-sm" onChange={this.handleChangeBackwork} ></input></div>
              </div>
              <div hidden={this.state.data.backwork.length == 0} className="table-responsive">
                <table className="table table-sm table-light table-striped">
                  <thead>
                    <tr>
                      <th>Date</th><th>Retification</th><th>FAA</th><th>Sign</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data.backwork.map((x, i) => <tr key={i}>
                      <td>{x.date_backwork_view || x.date_backwork}</td><td>{x.retification}</td><td>{x.faa}</td><td>{x.sign}</td>
                      <td><i onClick={() => this.removeBackwork(i)} className="fas fa-trash-alt" ></i></td>
                    </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LogbookPage;
