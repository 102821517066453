import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import DateCombo from './DateCombo'
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';
import * as common from './Common';
import MyModal from './MyModal';
import Autocomplete from './AutoComplete';

class LogbookLeg extends Component {
    constructor(props) {
        super(props);
        common.getData('combo/logbook').then((data) => { this.setState({ logbooks: data }) });

    }
    componentDidMount() {
        //Years
        let year = new Date().getFullYear();
        let years = [];
        for (let index = year + 1; index >= 2013; index--) {
            years.push(index);

        }
        this.bindList(years[0]);
        this.barList(years)
    }
    bindList(year) {
        this.props.show();
        var that = this;
        common.getData('logbook/legs/' + year).then((data) => {
            that.props.hide();
            let items = [];
            let legIndex = 0, fuelIndex = 0;
            let lastFlight = null;
            let flight = null;
            let legLenth = 0;
            data.legs.forEach(leg => {
                if (leg.flight_id != lastFlight) {
                    if (flight && flight.items) {
                        flight.items = flight.items.sort((a, b) => b.index - a.index);
                    }
                    lastFlight = leg.flight_id;
                    flight = { unique_id: leg.unique_id, type_id: leg.flight_type_id, items: [] };
                    items.push(flight);
                    legLenth = data.legs.filter(x => x.unique_id == leg.unique_id).length;
                    legIndex = 1;
                }

                leg.index = legIndex;
                flight.items.push(leg);
                fuelIndex = 0;
                legIndex++;
                let fuelings = data.fueling.filter(x => x.leg_id === leg.id && !x.added);
                fuelings.forEach(fuel => {
                    fuel.index = legIndex;
                    fuel.added = true;
                    fuel.logbook_name = leg.logbook_name;
                    flight.items.push(fuel);
                    fuelIndex++;
                    legIndex++;
                });
            });
            let fuelingsWithNoLeg = data.fueling.filter(x => x.leg_id === null || x.leg_id === "0");
            fuelingsWithNoLeg.forEach(fuel => {
                fuel.index = fuelIndex;
                items.push(fuel);
                fuelIndex++;
            });
            if (flight && flight.items) {
                flight.items = flight.items.sort((a, b) => b.index - a.index);
            }
            this.setState({ items, passengers_default: data.passengers });
        });
    }
    state = {
        items: null,
        fuelingIndex: 1,
        passengers: [],
        passengers_default: [],
        cities: [],
        countries: [],
        continents: [],
        logbooks: [],
        itemSelected: null,
        years: []
    }
    barList(years) {
        this.props.changeTitle({
            left: 'Log Book', right:
                <div className="no-break">
                    <div className="block-inline mr-3">
                        <select name="year" className="form-control form-control-sm" value={this.state.year} onChange={this.filterYear.bind(this)} >
                            {years.map(x => <option key={x} value={x}>{x}</option>)}
                        </select>
                    </div>
                    <button type="button" onClick={() => this.props.history.push('/admin/logbook-book')} className="btn-top mr-2" hidden={common.isCliente()} >Log Books</button>
                </div>
        });
    }
    barForm = () => {
        this.props.changeTitle({ left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>, center: 'Trecho' });
    }
    back() {
        this.barList();
        document.getElementById('new').className = 'form go';
        document.getElementById('list').className = '';

    }
    handleChange = e => {

        if (e.target.name === "logbook") {
            this.state.itemSelected.logbook_id = e.target.value;
            return this.setState({ itemSelected: this.state.itemSelected });
        }
        let data = this.state.data;
        data[e.target.name] = e.target.value;
        this.setState({ data })
    }

    openLogbookWindow = (item, e) => {
        e.stopPropagation();

        this.setState({ showModal: true, itemSelected: item });
    }
    saveLogbookId = () => {
        let item = this.state.itemSelected;
        if (item.logbook_id == null)
            item.logbook_id = this.state.logbooks[0].id;
        let logbook = this.state.logbooks.find(x => x.id == item.logbook_id);
        item.logbook_name = logbook.book_name + '-' + logbook.page;
        common.postData('logbook/save-logbook-id', { id: item.id, logbook_id: item.logbook_id, is_leg: item.date_fueling_view ? false : true }).then(() => {
            this.setState({ showModal: false, itemSelected: item });
        });
    }
    cancelLogbookId = () => {
        let item = this.state.itemSelected;
        item.logbook_id = null;
        item.logbook_name = null;
        common.postData('logbook/save-logbook-id', { id: item.id, logbook_id: item.logbook_id, is_leg: item.date_fueling_view ? false : true }).then(() => {
            this.setState({ showModal: false, itemSelected: item });
        });
    }
    showPDF = (id) => {
        if (!id || id == null)
            return alert('Ainda não há Log Book associado a este trecho!');

        window.open(common.api_url + 'shared/pdf-page/' + id, '_blank');

    }
    redirectToLeg(item, e) {
        e.stopPropagation();
        this.props.history.push(`/flight-leg-edit/${item.flight_id}/${item.id}`);

    }
    filterYear = (e) => {
        let year = e.target.value 
        setTimeout(() => { this.bindList(year); }, 1);
    }
    render() {

        return (
            <React.Fragment>
                <MyModal handleShow={this.state.showModal} handleClose={() => { this.setState({ showModal: false }) }} title="Logbook" >
                    <div>
                        <div className="label">
                            <select className="form-control" name="logbook" value={this.state.itemSelected && this.state.itemSelected.logbook_id + ""} onChange={this.handleChange} >
                                {this.state.logbooks.map((x, i) => <option key={i} value={x.id} >{x.name}</option>)}
                            </select>
                        </div>
                        <div className="mt-2">
                            <button disabled={this.props.isLoading} className="btn btn-sm btn-80 btn-secondary" onClick={this.cancelLogbookId.bind(this)} >Remover</button>
                            <button disabled={this.props.isLoading} className="btn btn-sm btn-80 btn-primary float-right" onClick={this.saveLogbookId.bind(this)} >Salvar</button>
                        </div>
                    </div>
                </MyModal>
                <div id="list" className="logbook">

                    {(!this.state.items) ? <div></div> :
                        this.state.items.length === 0 ? <div className="bg-cream rounded text-center p-2">- Ainda não há nenhuma trecho cadastrado</div> :
                            <div>
                                {this.state.items.map((flight, f) => <div className="rounded mb-1" key={f} >
                                    {flight.items && flight.items.map((x, i) => <React.Fragment key={i} >
                                        {!x.date_fueling_view ? //LEG
                                            <div className={'row no-gutters position-relative list-item bg-cream-dark pointer'} onClick={this.showPDF.bind(this, x.logbook_id)} >

                                                <div className="col-1 bg-blue text-center" ><div className="v-align" >{x.index}</div></div>
                                                <div className="col p-1 pt-0">
                                                    <div className="row no-gutters" >
                                                        <div className="mb-1 font-sm col">{x.date_start_view} - {x.date_end_view}</div>
                                                        <div className="mb-1 font-sm col-auto"><button style={{ height: 20 }} type="button" onClick={this.redirectToLeg.bind(this, x)} className="rounded btn-secondary">Editar</button></div>
                                                    </div>
                                                    <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.f_from}</div>
                                                    <i className="fas fa-arrow-circle-right ml-1 mr-1"></i>
                                                    <div className="badge badge-success" style={{ width: 60, fontSize: 12 }} >{x.f_to}</div>
                                                    <div className="block-inline ml-2"><b><i className="fas fa-plane"></i> </b>{x.f_ftime ? x.f_ftime.substring(0, 5) + 'hs' : 'Não informado'}</div>
                                                </div>
                                                <div className="col-2 bg-blue font-sm text-center " onClick={this.openLogbookWindow.bind(this, x)} >
                                                    <div className="v-align no-break" >
                                                        <i className="fas fa-book mr-1"></i><br />{x.logbook_name || ''}
                                                    </div>
                                                </div>
                                            </div>
                                            : //FUELING
                                            <div className={'row no-gutters position-relative fuel list-item bg-cream-dark pointer font-sm'} onClick={this.showPDF.bind(this, x.logbook_id)} >

                                                <div className="col-1 bg-success text-center text-white " ><div className="v-align" >{x.index}</div></div>
                                                <div className="col p-1 pt-0">
                                                    <div className="row no-gutters">
                                                        <div className="col-12" hidden={x.leg_id !== '0'} >
                                                            <div className="badge badge-danger" style={{ fontSize: 12 }} >* Sem trecho associado!</div>
                                                        </div>
                                                        <div className="col-12">{x.date_fueling_view}</div>
                                                        <div className="col-12">
                                                            <div className="badge badge-primary" style={{ width: 60, fontSize: 12 }} >{x.station}</div>
                                                            <div className="block-inline ml-2" ><b>Galões: </b>{common.formatNumberNoDec(x.gal)}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-2 font-sm bg-success text-white text-center" style={{cursor : 'auto'}} onClick={(e) => { e.stopPropagation(); }} >
                                                    <div className="v-align no-break" >
                                                        <i className="fas fa-book mr-1"></i><br />{x.logbook_name || ''}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>)}
                                    <div className={'flight_name flight_name-' + flight.type_id}>{flight.unique_id}</div>
                                </div>
                                )}
                            </div>
                    }

                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(LogbookLeg)