import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DateCombo from './DateCombo'
import CurrencyFormat from 'react-currency-format';
import * as common from './Common';
import ReactTooltip from 'react-tooltip';
import MyModal from './MyModal';

class Flight extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

  }
  componentWillMount() {

  }
  barList() {
    this.props.changeTitle({ left: null, center: 'Vôos', right: <button type="button" onClick={() => this.newData()} className="btn-right" hidden={common.isCliente()}>Novo</button> });
  }
  barForm = () => {
    this.props.changeTitle({ left: <div className="btn-back" onClick={() => this.back()}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div>, center: 'Vôo' });
  }
  back() {
    this.barList();
    document.getElementById('new').className = 'form go';
    document.getElementById('list').className = '';
    document.getElementById('filter').className = 'filter';
    common.scrollLast();

  }
  newData() {
    common.scrollTop();
    this.setState({ data: this.getNewData() });
    document.getElementById('new').className = 'form come';
    document.getElementById('list').className = 'hidden';
    document.getElementById('filter').className = 'hidden';
    this.barForm();


  }
  editData(item) {
    this.props.show();

    common.getData('flight/' + item.id).then((data) => {
      this.props.hide();
      common.scrollTop();
      data.passenger = 0;
      data.crew_index = 0;
      let date_start = common.checkDate(data.date_start_view);
      data.year = date_start.getFullYear();
      data.month = date_start.getMonth() + 1;
      data.day = date_start.getDate();
      data.unique_id = item.unique_id;
      data.aircraft_id = data.aircraft_id;
      this.setState({ data: data })
      document.getElementById('list').className = 'hidden';
      document.getElementById('filter').className = 'hidden';
      document.getElementById('new').className = 'form come';
      this.barForm();
    });
  }
  bindList() {
    this.props.show();
    var that = this;
    common.getData('flight/get-by-year/' + this.state.year).then((data) => { that.props.hide(); this.setState({ items: data, itemsAll: data }) });
  }
  componentDidMount() {

    this.barList();

    //Get list
    var that = this;
    this.props.show();
    common.getData('flight/get-by-year/0').then((data) => {
      that.props.hide();
      let year = data.length > 0 ? data[0].date_start.toString().substring(0, 4) : new Date().getFullYear();
      this.setState({ items: data, itemsAll: data, year });

    });
    common.getData('combo/flight-types').then((data) => { this.setState({ flightTypes: data }) });
    common.getData('combo/flight-status').then((data) => { this.setState({ flightStatus: data }) });
    common.getData('airplane/data/all').then((data) => { this.setState({ airplanes: data }) });

    common.getData('combo/passenger').then((data) => { this.setState({ passengers: data }) });
    common.getData('combo/crew').then((data) => { this.setState({ crew: data }) });

    //Years
    let year = new Date().getFullYear()
    let years = [];
    for (let index = year + 1; index >= 2013; index--) {
      years.push(index);

    }
    this.setState({ years });
  }
  state = {
    itemsAll: [],
    items: null,
    flightTypes: [],
    airplanes: [],
    flightStatus: [],
    sortField: '',
    years: [],
    year: new Date().getFullYear(),
    passengers: [],
    crew: [],
    data: this.getNewData(),
    files: []

  }
  getNewData() {

    return {
      id: 0,
      aircraft_id: 2,
      passenger: 0,
      passengers: [],
      crew_index: 0,
      crew: this.state ? this.state.crew.filter(x => x.id < 3) : [],
      date_start: new Date(),
      type_id: this.state && this.state.flightTypes.length > 0 ? this.state.flightTypes[0].id : null,
      description: '',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      status_id: 'OK'
    }
  }
  handleChange = e => {
    let data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({ data })

  }
  save() {

    let data = this.state.data;

    if (!common.isValidDate(data.year + "-" + data.month + "-" + data.day)) return alert('Data Inválida!');

    if (data.status_id === 'CA' && (!data.status_reason || data.status_reason === ''))
      return alert('Informe o motivo do cancelamento!');

    if (!data.type_id)
      data.type_id = this.state.flightTypes[0].id;
    
    this.props.show();
    var that = this;
    data.user_id = common.getUser().id;
    if (!data.aircraft_id)
      data.aircraft_id = this.state.airplanes[0].id;

    common.postData('flight', data).then(function (data) {
      console.log(data);
      that.props.hide();
      that.bindList();
      that.back();
    });

  }
  addPassenger() {

    let data = this.state.data;
    if (data.passenger == "-1") {
      this.addPassengerOther();
      return;
    }
    let newItem = this.state.passengers[this.state.data.passenger];
    let p = data.passengers.filter(x => { return parseInt(x.id) === parseInt(newItem.id) });

    if (p.length > 0) return alert('Este passageiro já foi adicionado!')
    data.passengers.push(newItem);
    this.setState({ data });
  }
  addPassengerOther() {
    let name = document.getElementById('passenger_other').value.trim();
    if (!name || name === "") return alert('Digite o nome do novo passageiro!')
    let data = this.state.data;
    let p = data.passengers.filter(x => { return x.name === name });
    if (p.length > 0) return alert('Este passageiro já foi adicionado!')
    data.passengers.push({ id: name, name: name });
    data.passenger = 0;
    this.setState({ data });
    document.getElementById('passenger_other').value = '';
  }
  addCrew() {
    let data = this.state.data;
    let newItem = this.state.crew[this.state.data.crew_index];
    let p = data.crew.filter(x => { return parseInt(x.id) === parseInt(newItem.id) });

    if (p.length > 0) return alert('Este tripulante já foi adicionado!')
    data.crew.push(newItem);
    this.setState({ data });
  }
  removePassenger(id) {
    let data = this.state.data;
    for (let i = 0; i < data.passengers.length; i++) {
      if (data.passengers[i].id == id) {
        data.passengers.splice(i, 1);
        break;
      }
    }
    this.setState({ data });
  }
  removeCrew(id) {
    let data = this.state.data;
    for (let i = 0; i < data.crew.length; i++) {
      if (data.crew[i].id == id) {
        data.crew.splice(i, 1);
        break;
      }
    }
    this.setState({ data });
  }
  tableDelete(id) {
    if (!window.confirm("Confirma a exclusão do registro?"))
      return;

    for (let index = 0; index < this.state.items.length; index++) {
      if (this.state.items[index].id === id) {
        this.state.items.splice(index, 1);
        break;
      }

    }
    this.setState({ items: this.state.items });
  }
  tableSort(key) {

    let direction = this.state.sortField === key ? 1 : -1;

    let data = this.state.items.sort((a, b) => {
      if (a[key] < b[key]) return 1 * direction;
      if (a[key] > b[key]) return -1 * direction;
      return 0;
    });
    this.setState({ items: data, sortField: (key === this.state.sortField ? '' : key) });
  }

  openPanel(id) {
    document.getElementById(id).style.display = 'block';
    document.getElementById(id).className = 'panel panel-come';

    setTimeout(() => {
      if (document.getElementById(id) != null)
        document.getElementById(id).className = 'panel panel-go';
    }, 3000);
  }
  formatLegs(legs) {
    if (!legs)
      return;
    let legsArray = legs.split(',');
    let legsArrayClean = []
    var lastLeg = '';
    legsArray.forEach(x => {
      if (x !== lastLeg)
        legsArrayClean.push(x);
      lastLeg = x;
    })
    return (
      legsArrayClean.map((x, i) =>
        <div key={i} className="block-inline badge badge-primary mr-1 font-xs">{x}</div>
      )
    )
  }
  downloadZip = (flight, e) => {
    e.stopPropagation();
    this.props.show();
    fetch(common.api_url + 'shared/zip-images/' + flight).then(data => data.text()).then((data) => {
      this.props.hide();
      if (data === "-1")
        return alert('Nenhum comprovante encontrado para este vôo!');

      var link = document.createElement('a');
      link.download = flight + '.zip';
      link.href = common.image_url + 'upload/zips/' + flight + '.zip';
      link.click();
    });
  }
  filter = (e) => {
    let items = [];
    if (e.target.value == '')
      items = this.state.itemsAll;
    else {
      let value = e.target.value.toUpperCase();
      items = this.state.itemsAll.filter(x =>
        x.unique_id.toUpperCase().indexOf(value) >= 0 || (x.legs + "").indexOf(value) >= 0 || x.date_start.indexOf(value) >= 0);
    }
    this.setState({ items });
  }
  filterYear = (e) => {
    this.setState({ year: e.target.value })
    setTimeout(() => { this.bindList(); }, 1);
  }
  deleteFlight = (x, e) => {
    e.stopPropagation();
    if (!window.confirm('Deseja excluir o vôo ' + x.unique_id + '?'))
      return;

    this.props.show();
    var that = this;
    x.user_id = common.getUser().id;
    common.postData('flight/delete-flight', x).then(function (data) {
      if (data === 1) {
        that.props.hide();
        that.bindList();
        that.back();
      }
    });
  }
  //ATTACHMENT
  fileWindow = (unique_id, flight_id, e) => {
    e.stopPropagation();
    common.getData('flight/files/' + flight_id).then((files) => {
      this.setState({ unique_id: unique_id, flight_id: flight_id, file_description: '', showModal: true, files });

    });
  }
  fileDelete = (x) => {

    if (!window.confirm('Deseja excluir este arquivo?'))
      return;
    common.postData('flight/file-delete', { unique_id: this.state.unique_id, name: x.name }).then((files) => {
      this.setState({ files });
    });
  }
  fileUpload = (x) => {

    if (!this.state.file_description || this.state.file_description == '')
      return alert('Informe um nome ou descriçao do arquivo!');
    var myFiles = document.getElementById('myFile');
    if (myFiles.files.length === 0 || !myFiles.files[0] || myFiles.files[0] === "")
      return;

    var file = myFiles.files[0];
    var formData = new FormData();
    formData.append('file', file);
    formData.append('unique_id', this.state.unique_id);
    formData.append('flight_id', this.state.flight_id);
    formData.append('description', this.state.file_description);
    formData.append('user_id', common.getUser().id);
    this.props.show();
    var that = this;

    fetch(common.api_url + 'flight/file-upload', {
      method: 'post', body: formData, headers: { Authorization: 'Bearer ' + common.getUser().access_token }
    }).then(dataReturn => dataReturn.json()).then(function (files) {
      that.props.hide();
      if (files === 0)
        return alert('Não foi possível anexar o arquivo.');

      that.setState({ files, file_description: '' });

    });
  }
  render() {
    return (
      <React.Fragment>
        <MyModal handleShow={this.state.showModal} handleClose={() => { this.setState({ showModal: false }) }} title={'Anexos ' + this.state.unique_id} >
          <div>
            <input type="text" id="file_description" name="file_description" className="form-control mb-2" placeholder="Descrição"
              value={this.state.file_description} onChange={(e) => { this.setState({ file_description: e.target.value }) }}  ></input>
            <button className="btn btn-primary" onClick={() => { document.getElementById('myFile').click() }} >Upload</button>
            <input type="file" id="myFile" name="myFile" onChange={this.fileUpload} hidden  ></input>
            <div>
              <table className="table table-light table-bordered table-striped table-sm mt-2" hidden={this.state.files.length === 0} >
                <thead>
                  <tr>
                    <th>Arquivos</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.files.map((x, i) => <tr key={i} >
                    <td><a href={common.image_url + 'upload/flight/' + this.state.unique_id + '/' + x.image_name} target="_blank" >
                      {x.image_name}
                      {x.description ? <div className="text-info"><b>{x.description}</b></div> : null}
                    </a>
                    </td>
                    <td><i className="fas fa-trash" onClick={this.fileDelete.bind(this, x)} ></i></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </MyModal>
        <div className="filter" id="filter" >
          <div className="row m-0" >
            <input type="text" className="form-control form-control-sm col-8 col-md-6 offset-md-2" placeholder="Buscar..." onChange={this.filter.bind(this)} />
            <div className="col-4 col-md-2 pl-1 pr-0">
              <select name="year" className="form-control form-control-sm" value={this.state.year} onChange={this.filterYear.bind(this)} >
                {this.state.years.map(x => <option key={x} value={x}>{x}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="div-table"></div>
        <div id="list">
          {(!this.state.items) ? <div></div> :
            this.state.items.length === 0 ? <div className="bg-cream text-center rounded p-2">- Ainda não há nenhum vôo cadastrado</div> :
              <React.Fragment>
                {this.state.items.map(x => <div onClick={common.isCliente() ? null : this.editData.bind(this, x)} className="rounded bg-blue font-sm mb-1 position-relative pointer" key={x.id}>
                  <div className="bg-blue p-1 font-xs row no-gutters">
                    <div className="col">
                      {x.date_start_view} {x.date_end_view && '- ' + x.date_end_view}
                    </div>
                    <div className="col text-right">
                      {x.aircraft_name}
                    </div>
                  </div>
                  <div className="row m-0 no-gutters">
                    <div className={'col-2 text-center flight-id flight-id-' + x.type_id} >
                      <span className="hidden-xs">{x.unique_id}</span>
                      <span className="show-xs">{x.unique_id.split('-')[0]}<br />{x.unique_id.split('-')[1] + '-' + x.unique_id.split('-')[2]}</span>
                    </div>
                    <div className="col-8 p-1 bg-cream">
                      <div className="mb-1">{this.formatLegs(x.legs)}</div>
                      <div className="flight-labels" >
                        <div><b><i className="fas fa-globe-asia"></i> Trechos:</b> {x.legCount || 0}</div>
                        <div><b><i className="fas fa-plane"></i> Tempo: </b>{x.flight_time ? x.flight_time.substring(0, 5) : 'Não informado'}</div>
                        <div><b><i className="fas fa-user-friends"></i> Passageiros:</b> {x.passengerCount || 0}</div>
                        <div><i className="fas fa-coins"></i><b> Despesas Total: </b><b className={parseFloat(x.amount_dollar_min) === 0 ? 'text-danger' : 'text-green'}>${common.formatNumber(x.expense_amount)}</b></div>
                        <div><i className="fas fa-coins"></i><b> Despesas Hora: </b><b className={parseFloat(x.amount_dollar_min) === 0 ? 'text-danger' : 'text-green'}>${x.expense_hour ? common.formatNumber(x.expense_hour) : '0'}</b></div>
                        <div><i className="fas fa-gas-pump"></i><b> Abastecimento: </b>{common.formatNumberNoDec(x.gals) || 0} gals</div>
                      </div>
                      <div><i>{x.description}</i></div>
                      <div className="font-sm text-right mt-1">
                        <b>{x.image_count}</b><i onClick={this.fileWindow.bind(this, x.unique_id, x.id)} className="fas fa-paperclip ml-1 big-icon" ></i>
                        <i onClick={this.downloadZip.bind(this, x.unique_id)} className="fas fa-download ml-3 big-icon hidden-xs" ></i>
                        <i onClick={(e) => { e.stopPropagation(); this.props.history.push('/flight-image/' + x.id) }} className="fas fa-images  big-icon ml-3" ></i>
                        <i hidden={x.status_id !== 'CA'} title={x.status_reason} className="fas fa-plane big-icon text-orange ml-3" ></i>

                      </div>

                    </div>
                    <div className="col-2 text-center p-1 bg-blue" onClick={(e) => { e.stopPropagation(); this.props.history.push('/flight-leg/' + x.id) }} >
                      <div className="v-align link"><i className="fas fa-plane font-lg"></i><br />Trechos</div>
                    </div>
                  </div>
                </div>
                )}
              </React.Fragment>
          }
        </div>
        <div id="new" className="form">
          <div>
            <div className="label">Aeronave</div>
            <select className="form-control" name="aircraft_id" value={this.state.data.aircraft_id} onChange={this.handleChange}  >
              {this.state.airplanes.map((x, i) => <option key={x.id} value={x.id} >{x.name}</option>)}
            </select>
          </div>
          <div>
            <div className="label">Data Início do Vôo</div>
            <DateCombo year={this.state.data.year} month={this.state.data.month} day={this.state.data.day} onChange={this.handleChange} />
          </div>
          <div>
            <div className="label">Tipo</div>
            <select className="form-control" name="type_id" value={this.state.data.type_id} onChange={this.handleChange} disabled={this.state.data.id !== 0} >
              {this.state.flightTypes.map((x, i) => <option key={x.id} value={x.id} >{x.name}</option>)}
            </select>
          </div>
          <div>
            <div className="label">Descrição</div>
            <input type="text" placeholder="Descrição..." className="form-control" name="description" value={this.state.data.description || ""} onChange={this.handleChange}  ></input>
          </div>
          <div>
            <div className="label">Tripulação</div>
            <select className="form-control select-button" name="crew_index" value={this.state.data.crew_index} onChange={this.handleChange} >
              {this.state.crew.map((x, i) => <option key={x.id} value={i} >{x.name}</option>)}
            </select>
            <button className="btn btn-primary" style={{ width: 100 }} onClick={() => this.addCrew()} >Adicionar</button>
          </div>
          <div className="mt-2 rounded">
            {this.state.data.crew.map((x, i) => <div className="bg-list-alternate" key={x.id} >{i + 1}) {x.name}  <i onClick={() => this.removeCrew(x.id)} className="fas fa-trash-alt float-right mr-2 mt-1" ></i> </div>)}
          </div>
          <div hidden={this.state.data.id === 0}>
            <div className="label">Status</div>
            <select className="form-control" name="status_id" value={this.state.data.status_id || "OK"} onChange={this.handleChange} >
              {this.state.flightStatus.map((x, i) => <option key={x.id} value={x.id} >{x.name}</option>)}
            </select>
          </div>
          <div hidden={this.state.data.status_id !== 'CA'}>
            <div className="label">Motivo Cancelamento</div>
            <input type="text" className="form-control" name="status_reason" value={this.state.data.status_reason || ""} onChange={this.handleChange} />
          </div>
          <div className="text-right pt-2">
            <button className="btn btn-main" onClick={() => this.save()} >Salvar</button>
          </div>
          <div className="pt-2" hidden={this.state.data.id === 0} >
            <button className="btn btn-danger" onClick={this.deleteFlight.bind(this, this.state.data)} >Excluir Vôo</button>
          </div>
        </div>
        <ReactTooltip />
      </React.Fragment >
    );
  }
}

export default withRouter(Flight);
