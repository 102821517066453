import React, { Component } from 'react';
import * as common from '../Common';


class LogbookBook extends Component {
  constructor(props) {
    super(props);

    this.barList();
    this.bindList();

  }
  state = {
    items: [],
    itemsAll: [],
    sortField: '',
    filter: '',
    expense_types: [],
    data: {},


  }
  barList() {
    this.props.changeTitle({ left: <div className="pointer" onClick={() => window.history.back()} ><i className="fas fa-arrow-alt-circle-left" ></i> Voltar</div>, center: 'Log Books', right: <button type="button" onClick={this.newData.bind(this)} className="btn-right">Novo</button> });
  }
  barForm = () => {
    this.props.changeTitle({ left: <div className="btn-back" onClick={this.back.bind(this)}><i className="fas fa-arrow-alt-circle-left"></i> Voltar</div> });
  }
  bindList() {
    this.props.show();
    var that = this;
    common.getData('logbook-book').then((data) => { that.props.hide(); this.setState({ items: data, itemsAll: data }) })
  }
  newData() {
    common.scrollTop();
    this.setState({ data: this.getNewData() });
    common.formShow();
    this.barForm();
  }
  back() {
    this.barList();
    common.formHide();
  }
  editData(item) {

    let link = document.createElement('a');
    link.href = common.api_url + 'shared/pdf-book/' + item.id
    link.target = '_blank';
    link.click();

    // this.props.show();
    // common.getData('logbook-book/' + item.id).then((data) => {
    //   this.props.hide();
    //   common.scrollTop();
    //   data.active = data.active === '1';
    //   this.setState({ data: data })
    //   common.formShow();
    //   this.barForm();
    // });
  }
  save() {
    this.props.show();
    var that = this;
    this.state.data.user_id = common.getUser().id;
    common.postData('logbook-book', this.state.data).then(function (data) {
      that.props.hide();
      that.bindList();
      that.back();
    });

  }
  getNewData() {

    return {
      id: 0,
      active: 1,
      permission_id: 1
    }
  }
  handleChange = e => {
    let data = this.state.data;
    if (e.target.type === 'checkbox')
      data[e.target.name] = e.target.checked ? 1 : 0;
    else
      data[e.target.name] = e.target.value;

    this.setState({ data })

  }
  filter(e) {
    let items = [];
    if (e.target.value === '')
      items = this.state.itemsAll;
    else
      items = this.state.itemsAll.filter(x => x.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0);
    this.setState({ items });
  }
  render() {

    return (
      <React.Fragment>
        <div className="filter" id="filter" >
          <input type="text" className="form-control form-control-sm col-md-8 offset-md-2" placeholder="Buscar..." onChange={this.filter.bind(this)} />
        </div>
        <div id="list" className="div-table">
          <table className="table table-light table-hover table-bordered table-striped table-sm" >
            <thead>
              <tr>
                <th onClick={common.tableSort.bind(this, 'name')} >Nome</th>
                <th onClick={common.tableSort.bind(this, 'created_at_view')} >Data</th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map(x => <tr key={x.id} onClick={this.editData.bind(this, x)} >
                <td>{x.name}</td>
                <td>{x.created_at_view}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
        <div id="new" className="form" >
          <div>
            <div className="label">Nome</div>
            <input type="text" placeholder="Nome..." className="form-control" name="name" value={this.state.data.name || ""} onChange={this.handleChange}  ></input>
          </div>
          <div className="text-right pt-2">
            <button className="btn btn-main" onClick={this.save.bind(this)} >Salvar</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LogbookBook;
